import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Container, FormControlLabel, FormGroup, Grid, MenuItem, Switch, Typography} from "@mui/material";
import {ExternalConnection} from "../../classes/model/ExternalConnection";
import DBForm from "./dialogSections/DBform";
import {BackEndUtils} from "../../utils/BackEndUtils";
import KafkaForm, {MQTTForm} from "./dialogSections/KafkaForm";
import FileForm from "./dialogSections/FileForm";
import {AlertContext} from "../generic/AlertContext";
import {Constants} from "../../utils/Constants";
import TimeSeriesForm from "./dialogSections/TimeSeriesForm";
import ZipForm from "./dialogSections/ZipForm";

export default function DialogExternalConnection({
                                                     openDialog,
                                                     setOpenDialog,
                                                     isEditing,
                                                     isTempoForce = false,
                                                     addTempoToList = (x) => {
                                                     },
                                                     forInput = false,
                                                     externalConnection,
                                                     refreshInputs,
                                                     user
                                                 }) {

    const {setContext} = useContext(AlertContext);

    const [newOutput, setNewOutput] = useState<ExternalConnection | null>(externalConnection || null);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [isRelational, setIsRelational] = useState(newOutput.modelType == Constants.MODEL_TYPE.MULTI_TABLE);

    useEffect(() => {
        setNewOutput(externalConnection);
        if (!newOutput.modelType) {
            setNewOutput({...newOutput, modelType: Constants.MODEL_TYPE.SINGLE_TABLE})
        }
    }, [externalConnection, externalConnection.id]);
    useEffect(() => {
        setButtonDisabled(!newOutput.isComplete(newOutput, forInput));
    }, Object.values(newOutput));

    useEffect(() => {
        setNewOutput({...newOutput, modelType: isRelational ? Constants.MODEL_TYPE.MULTI_TABLE : Constants.MODEL_TYPE.SINGLE_TABLE})
    }, [isRelational]);


    const be = BackEndUtils.getInstance();

    let modelTypes = [
        {
            value: Constants.MODEL_TYPE.SINGLE_TABLE,
            label: 'Single Table'
        },
        {
            value: Constants.MODEL_TYPE.MULTI_TABLE,
            label: 'Relational'
        },
        {
            value: Constants.MODEL_TYPE.TIME_SERIES,
            label: 'Time Series'
        }
    ];
    let connectionTypes = [
        {
            value: Constants.EXTERNAL_TYPE.POSTGRES,
            label: 'Postgres',
        },
        !forInput && {
            value: Constants.EXTERNAL_TYPE.KAFKA,
            label: 'Kafka',
        },
        !forInput && {
            value: Constants.EXTERNAL_TYPE.MQTT,
            label: 'MQTT',
        },
        {
            value: Constants.EXTERNAL_TYPE.FILE,
            label: 'Permanent file'
        }
    ];

    if (isTempoForce) {
        connectionTypes = [
            {
                value: Constants.EXTERNAL_TYPE.TEMPO,
                label: 'Temporary file'
            }
        ]
    }

    function editOne() {
        if (newOutput?.content) {
            be.sendPutWithFiles(`/external-connection/${newOutput?.id}/withFile`, {
                ...newOutput,
                owner: user.email,
                input: forInput
            }, newOutput.content).then(
                (l) => {
                    setContext({
                        text: Constants.MESSAGES.SUCCESS_TITLE,
                        content: Constants.MESSAGES.SUCCESS_CONTENT,
                        severity: Constants.ALERT_SEVERITY.SUCCESS,
                        open: true
                    })
                    refreshInputs();
                },
                (error) => {
                    setContext({
                        text: Constants.MESSAGES.ERROR_TITLE,
                        content: Constants.MESSAGES.ERROR_CONTENT,
                        severity: Constants.ALERT_SEVERITY.ERROR,
                        open: true
                    })
                });
        } else {
            be.sendPut(`/external-connection/${newOutput?.id}`, {...newOutput}).then(
                (l) => {
                    setContext({
                        text: Constants.MESSAGES.SUCCESS_TITLE,
                        content: Constants.MESSAGES.SUCCESS_CONTENT,
                        severity: Constants.ALERT_SEVERITY.SUCCESS,
                        open: true
                    })
                    refreshInputs();
                },
                (error) => {
                    setContext({
                        text: Constants.MESSAGES.ERROR_TITLE,
                        content: Constants.MESSAGES.ERROR_CONTENT,
                        severity: Constants.ALERT_SEVERITY.ERROR,
                        open: true
                    })
                });
        }
        setOpenDialog(false);
    }

    const checkModelType = () => {
        return newOutput?.modelType != null ? newOutput.modelType : Constants.MODEL_TYPE.SINGLE_TABLE;
    }

    function createOne() {
        if (newOutput?.content) {
            if (newOutput.type === "temporary") {
                addTempoToList(newOutput);
            } else {
                let path = newOutput?.modelType === Constants.MODEL_TYPE.MULTI_TABLE ? "/external-connection/withZipFile/" : "/external-connection/withFile/";
                be.sendPostWithFiles(path, {
                    ...newOutput,
                    owner: user.email,
                    input: forInput
                }, newOutput?.content).then(
                    (l) => {
                        setContext({
                            text: Constants.MESSAGES.SUCCESS_TITLE,
                            content: Constants.MESSAGES.SUCCESS_CONTENT,
                            severity: Constants.ALERT_SEVERITY.SUCCESS,
                            open: true
                        })
                        refreshInputs();
                    },
                    (error) => {
                        setContext({
                            text: Constants.MESSAGES.ERROR_TITLE,
                            content: Constants.MESSAGES.ERROR_CONTENT,
                            severity: Constants.ALERT_SEVERITY.ERROR,
                            open: true
                        })
                    });
            }
            setOpenDialog(false);
        } else {

            be.sendPost(`/external-connection/`, {...newOutput, modelType: checkModelType(), owner: user.email, input: forInput}).then((l) => {
                    setContext({
                        text: Constants.MESSAGES.SUCCESS_TITLE,
                        content: Constants.MESSAGES.SUCCESS_CONTENT,
                        severity: Constants.ALERT_SEVERITY.SUCCESS,
                        open: true
                    })
                    refreshInputs();
                },
                (error) => {
                    setContext({
                        text: Constants.MESSAGES.ERROR_TITLE,
                        content: Constants.MESSAGES.ERROR_CONTENT,
                        severity: Constants.ALERT_SEVERITY.ERROR,
                        open: true
                    })
                });
        }
        setOpenDialog(false);
    }

    return (
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
            <DialogTitle>Set up your connection</DialogTitle>
            <DialogContent>
                <Container>
                    <Grid container columnSpacing={2}>
                        {forInput && !isTempoForce && <Grid item xs={8}>
                            <TextField
                                id="modelType"
                                autoFocus
                                select
                                label="Model Type"
                                value={newOutput?.modelType}
                                onChange={(i) => {
                                    setNewOutput({...newOutput, modelType: i.target.value})
                                }}
                                helperText="Select the type of your model"
                                variant="standard"
                            >
                                {modelTypes.map((option) => {
                                    return option && <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                })}
                            </TextField>
                        </Grid>}
                        {(!forInput || newOutput?.modelType !== Constants.MODEL_TYPE.MULTI_TABLE) &&
                        <Grid item xs={8}>
                            <TextField
                                id="connectionType"
                                select
                                label={(forInput ? "Input" : "Output") + " type"}
                                value={newOutput?.type}
                                onChange={(i) => {
                                    setNewOutput({...newOutput, type: i.target.value})
                                }}
                                helperText={"Select the type of your " + (forInput ? "input" : "output")}
                                variant="standard"
                            >
                                {connectionTypes.map((option) => {
                                    return option && <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                })}
                            </TextField>
                        </Grid>}

                        {!forInput &&
                        <Grid item xs={4} alignSelf={"end"}>
                            <FormGroup>
                                <FormControlLabel control={<Switch onChange={() => setIsRelational(!isRelational)} checked={isRelational}/>}
                                                  label={"Relational"} sx={{fontSize: "10px", marginRight: "0px"}}/>
                            </FormGroup>
                        </Grid>}

                        <Grid item xs={8}>
                            <TextField id="connectionName"
                                       label={(forInput ? "Input" : "Output") + " name"}
                                       value={newOutput?.connectionName}
                                       onChange={(i) => {
                                           setNewOutput({...newOutput, connectionName: i.target.value})
                                       }}
                                       helperText={"Give a name to your " + (forInput ? "input" : "output")}
                                       variant="standard"/>
                        </Grid>

                        <Grid item xs={8}>
                            <TextField id={"description"}
                                       label={"Description"}
                                       value={newOutput?.description}
                                       onChange={(i) => setNewOutput({...newOutput, description: i.target.value})}
                                       variant={"standard"}
                            />
                        </Grid>

                        {/*CASE SINGLE TABLE*/}
                        {(!forInput || newOutput?.modelType !== Constants.MODEL_TYPE.MULTI_TABLE) && <>
                            {newOutput?.type === Constants.EXTERNAL_TYPE.POSTGRES &&
                            <DBForm newOutput={newOutput} setNewOutput={setNewOutput} forInput={forInput}/>}
                            {newOutput?.type === Constants.EXTERNAL_TYPE.KAFKA && <KafkaForm newOutput={newOutput} setNewOutput={setNewOutput}/>}
                            {newOutput?.type === Constants.EXTERNAL_TYPE.MQTT && <MQTTForm newOutput={newOutput} setNewOutput={setNewOutput}/>}
                            {newOutput?.type === Constants.EXTERNAL_TYPE.FILE &&
                            <FileForm newOutput={newOutput} setNewOutput={setNewOutput} forInput={forInput} isEditing={isEditing}/>}
                            {newOutput?.type === Constants.EXTERNAL_TYPE.TEMPO &&
                            <FileForm newOutput={newOutput} setNewOutput={setNewOutput} forInput={forInput} isEditing={isEditing}/>}
                        </>}


                        {/*CASE TIME SERIES*/}
                        {newOutput?.modelType === Constants.MODEL_TYPE.TIME_SERIES &&
                        <TimeSeriesForm newOutput={newOutput} setNewOutput={setNewOutput} forInput={forInput}/>}


                        {/*CASE MULTIPLE TABLE*/}
                        {forInput && <>
                            {newOutput?.modelType === Constants.MODEL_TYPE.MULTI_TABLE &&
                            <ZipForm newOutput={newOutput} setNewOutput={setNewOutput} forInput={forInput} isEditing={isEditing}/>}
                        </>}

                        {newOutput?.modelType === Constants.MODEL_TYPE.SINGLE_TABLE &&
                        <Grid item xs={8}>
                            <Typography style={{
                                fontWeight: "400",
                                fontSize: "0.75rem",
                                fontStyle: "italic",
                                marginTop: ".5rem"
                            }}>All fields are required</Typography>
                        </Grid>
                        }

                    </Grid>
                </Container>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => setOpenDialog(false)}>Exit</Button>
                {!isEditing && <Button variant="contained" disabled={buttonDisabled} onClick={() => createOne()}>Add</Button>}
                {isEditing && <Button variant="contained" onClick={() => editOne()}>Save</Button>}
            </DialogActions>
        </Dialog>
    );
}
