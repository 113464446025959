import React, {useState} from 'react';
import useUser from "../utils/hooks/useUser";
import Unauthorized from "./Unauthorized";
import {Constants} from "../utils/Constants";
import ProfileComponent from "../components/ProfileComponent";
import ProfileSkeleton from "../components/skeletons/ProfileSkeleton";
import {Grid} from "@mui/material";

const ProfilePage = () => {
    const {user, canActivate} = useUser(Constants.ROLES_LEVEL.ONE);

    const [toggleRefresh, setToggleRefresh] = useState(false);

    return (
        <>
            { user &&
                user.found && canActivate &&
				        <ProfileComponent user={user} toggle={toggleRefresh} setToggle={setToggleRefresh}/>
            }
            {user && !user.found && !canActivate && <Unauthorized/>}
            {!user && <Grid xs={12} item container justifyContent={"center"} alignItems={"center"}>
	            <ProfileSkeleton/>
            </Grid>}
        </>
    );
};

export default ProfilePage;
