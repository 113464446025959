import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IETIcon from "./customIcons/IETIcon";
import {Grid} from "@mui/material";
import MaterialUISwitch from "./customInputs/MaterialUISwitch";
import {useNavigate} from "react-router-dom";


export default function ButtonAppBar({mode, setMode}) {
    const navigate = useNavigate();
    return (
        <Box sx={{flexGrow: 1}} hidden={window.location.pathname.includes('dashboard')}>
            <AppBar position="static" sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}
                    style={{backgroundColor: "rgb(3, 6, 9,0.85)"}}>
                <Toolbar>
                    <Grid container item xs={6} md={2} columns={12} justifyContent={"flex-start"} onClick={()=>{navigate("/")}}  sx={{cursor: "pointer"}}>
                        <IETIcon theme={'dark'}/>
                    </Grid>
                    <Grid container justifyContent={"flex-end"}>
                        <MaterialUISwitch checked={mode === 'dark'} onClick={() => {
                            setMode(mode === 'light' ? 'dark' : 'light')
                        }}/>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
