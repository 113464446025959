import {useEffect, useState} from "react";
import AuthService from "../services/auth.service";
import User from "../../classes/model/User";

export default function useUser(rolesAllowed: string[]){
    const [user, setUser] = useState<User>(null);
    const [canActivate, setCanActivate] = useState(false);
    useEffect(() => {
        AuthService.getCurrentUser().then((res) => {
            if (res == null) {
                setUser({...user, found: false});
                setCanActivate(false);
                return;
            }
            setUser({...res.data.data, found: true})
            setCanActivate(rolesAllowed.every(r => res.data.data.authorities.includes(r)));
        });
    },[])

    return {user, canActivate};
}
