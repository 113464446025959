import styled from "@emotion/styled";
import {Grid} from "@mui/material";

export const GridRTL = styled(Grid)`
  button:first-child {
    margin-right: 0.75rem;
  }
  button {
    margin-left: 0.5rem;
  }
`;
;
