// @ts-ignore
import antlr4 from "antlr4"

export default class AceErrorListener extends antlr4.error.ErrorListener {
    private annotations: any;
    constructor(annotations: any) {
        super();
        antlr4.error.ErrorListener.call(this);
        this.annotations = annotations;
    }

    setMessage(offendingSymbol, msg, exceptionDetails) {
        //console.log(exceptionDetails);
        return msg;
    }

    syntaxError(recognizer, offendingSymbol, line, column, msg, e) {
        this.annotations.push({
            row: offendingSymbol.line - 1,
            column: column,
            text: this.setMessage(offendingSymbol, msg, e),
            type: "error",
            name: "missingDot"
        });
    }
}
