import React, {useContext, useEffect, useState} from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {Container, Grid} from "@mui/material";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {Project} from "../../classes/model/Project";
import {BackEndUtils} from "../../utils/BackEndUtils";
import {AlertContext} from "../generic/AlertContext";
import {Constants} from "../../utils/Constants";

const NewProjectDialog = ({
                              openDialog,
                              setOpenDialog,
                              editing = false,
                              project = null,
                              refresh,
                              user
                          }) => {

    const [newProject, setNewProject] = useState<Project | null>(project);
    const be = BackEndUtils.getInstance();
    const {setContext} = useContext(AlertContext);

    useEffect(() => setNewProject(project), [project])

    const handleClose = () => {
        setOpenDialog(false);
    };

    function editOne() {
        be.sendPut(`/project/${newProject?.id}`, newProject).then(l => {
            setContext({
                text: Constants.MESSAGES.SUCCESS_TITLE,
                content: Constants.MESSAGES.SUCCESS_CONTENT,
                severity: Constants.ALERT_SEVERITY.SUCCESS,
                open: true
            })
            refresh().then(l => handleClose());
        }, error => {
            setContext({
                text: Constants.MESSAGES.ERROR_TITLE,
                content: Constants.MESSAGES.ERROR_CONTENT,
                severity: Constants.ALERT_SEVERITY.ERROR,
                open: true
            })
        });
    }

    function createOne() {
        be.sendPost(`/project/create`, {...newProject, owner: user.email}).then(l => {
            setContext({
                text: Constants.MESSAGES.SUCCESS_TITLE,
                content: Constants.MESSAGES.SUCCESS_CONTENT,
                severity: Constants.ALERT_SEVERITY.SUCCESS,
                open: true
            })
            refresh().then(l => handleClose());
        }, error => {
            setContext({
                text: Constants.MESSAGES.ERROR_TITLE,
                content: error.response.data,
                severity: Constants.ALERT_SEVERITY.ERROR,
                open: true
            })
        });
    }

    return (
        <Dialog open={openDialog} onClose={handleClose}>
            <DialogTitle>Create a new project</DialogTitle>
            <DialogContent>
                <Container>
                    <Grid container>
                        <Grid item xs={8}>
                            <TextField
                                autoFocus
                                id="name"
                                label="Name"
                                value={newProject?.name}
                                onChange={(i) => setNewProject({...newProject, name: i.target.value})}
                                helperText="Give a name to your project"
                                type="text"
                                variant="standard">
                            </TextField>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                margin="dense"
                                id="description"
                                label="Give a short description"
                                value={newProject?.description}
                                onChange={(i) => setNewProject({...newProject, description: i.target.value})}
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </Grid>
                    </Grid>
                </Container>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Exit</Button>
                {!editing &&
                <Button disabled={newProject && newProject.name === ""} variant="contained" onClick={() => createOne()}>Add</Button>}
                {editing &&
                <Button disabled={newProject && newProject.name === ""} variant="contained" onClick={() => editOne()}>Modify</Button>}
            </DialogActions>
        </Dialog>
    // FIXME bottone ADD sempre abilitato
    );
};

export default NewProjectDialog;
