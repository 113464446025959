import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import {Grid} from "@mui/material";
import DataganDrawer from "../../components/DataganDrawer";
import {Outlet} from "react-router-dom";


function DashboardHome({mode, setMode}) {
    const [open, setOpen] = React.useState(true);

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <DataganDrawer open={open} setOpen={setOpen} dark={mode} setDarkMode={setMode}/>
            <Box component="main" sx={{flexGrow: 1, height: "100vh"}}>
                <Grid container alignItems={"stretch"} sx={{height: "100vh"}}>
                    <Outlet/>
                </Grid>
            </Box>
        </Box>
    );
}

export default DashboardHome;
