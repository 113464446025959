import React from "react";

function WhiteLogo() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            preserveAspectRatio="xMidYMid meet"
            version="1"
            viewBox="0 0 1893 198.929"
        >
            <defs>
                <clipPath id="clip1">
                    <path d="M354 0h106v31H354zm0 0"></path>
                </clipPath>
                <clipPath id="clip2">
                    <path d="M396.5.531c-11.2 1.5-23.2 7.2-31 14.899-4.8 4.699-11.5 13.398-11.5 14.8 0 .301 8.7.598 19.398.598 14.5 0 21-.398 25.602-1.598 5.398-1.402 7.102-1.402 12.398-.3 3.5.8 15 1.5 26.903 1.601l20.898.399-1.898-3.301c-1-1.8-5.2-6.7-9.301-10.899-5.5-5.601-9.3-8.5-14.398-10.902-10.102-4.797-25-6.898-37.102-5.297zm0 0"></path>
                </clipPath>
                <linearGradient
                    id="linear0"
                    x1="354"
                    x2="459.199"
                    y1="15.471"
                    y2="15.471"
                    gradientTransform="translate(0 -.003)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#4768B3" stopOpacity="1"></stop>
                    <stop offset="0.125" stopColor="#4668B3" stopOpacity="1"></stop>
                    <stop offset="0.25" stopColor="#4669B4" stopOpacity="1"></stop>
                    <stop offset="0.375" stopColor="#456AB4" stopOpacity="1"></stop>
                    <stop offset="0.5" stopColor="#446BB5" stopOpacity="1"></stop>
                    <stop offset="0.625" stopColor="#446CB6" stopOpacity="1"></stop>
                    <stop offset="0.75" stopColor="#436CB6" stopOpacity="1"></stop>
                    <stop offset="0.875" stopColor="#436DB7" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="#426EB7" stopOpacity="1"></stop>
                </linearGradient>
                <clipPath id="clip3">
                    <path d="M832 0h104v31H832zm0 0"></path>
                </clipPath>
                <clipPath id="clip4">
                    <path d="M872.2.828c-11.7 2.102-22.9 7.8-29.802 15.203C838.2 20.43 832 28.731 832 29.93c0 1.398 40.2.5 47.2-1 4.698-1.102 6.6-1 11.8.3 6.898 1.801 45 2.399 45 .7 0-1.2-6.2-9.5-10.398-13.899-5-5.3-13.301-10.3-21.5-12.902-8.5-2.7-23.403-3.8-31.903-2.3zm0 0"></path>
                </clipPath>
                <linearGradient
                    id="linear1"
                    x1="832"
                    x2="936"
                    y1="15.471"
                    y2="15.471"
                    gradientTransform="translate(0 -.003)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#3287C9" stopOpacity="1"></stop>
                    <stop offset="0.125" stopColor="#3188CA" stopOpacity="1"></stop>
                    <stop offset="0.25" stopColor="#3189CA" stopOpacity="1"></stop>
                    <stop offset="0.375" stopColor="#308ACB" stopOpacity="1"></stop>
                    <stop offset="0.5" stopColor="#308ACC" stopOpacity="1"></stop>
                    <stop offset="0.625" stopColor="#2F8BCC" stopOpacity="1"></stop>
                    <stop offset="0.75" stopColor="#2E8CCD" stopOpacity="1"></stop>
                    <stop offset="0.875" stopColor="#2E8DCD" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="#2D8ECE" stopOpacity="1"></stop>
                </linearGradient>
                <clipPath id="clip5">
                    <path d="M1486 0h106v31h-106zm0 0"></path>
                </clipPath>
                <clipPath id="clip6">
                    <path d="M1526.3.828c-12.3 2.203-25.1 9.402-32.1 17.8-4.4 5.403-8.2 10.7-8.2 11.602 0 .301 8.7.598 19.398.598 14.5 0 21-.398 25.602-1.598 5.2-1.3 7.102-1.402 11.8-.3 3.2.699 15.302 1.398 27 1.601l21.4.399-4.302-6.2c-8.097-11.5-18.597-19.101-31-22.5-6.796-1.8-22.796-2.601-29.597-1.402zm0 0"></path>
                </clipPath>
                <linearGradient
                    id="linear2"
                    x1="1486"
                    x2="1591.199"
                    y1="15.471"
                    y2="15.471"
                    gradientTransform="translate(0 -.003)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#15B3E8" stopOpacity="1"></stop>
                    <stop offset="0.125" stopColor="#15B4E9" stopOpacity="1"></stop>
                    <stop offset="0.25" stopColor="#14B4E9" stopOpacity="1"></stop>
                    <stop offset="0.375" stopColor="#14B5EA" stopOpacity="1"></stop>
                    <stop offset="0.5" stopColor="#13B6EB" stopOpacity="1"></stop>
                    <stop offset="0.625" stopColor="#12B7EB" stopOpacity="1"></stop>
                    <stop offset="0.75" stopColor="#12B8EC" stopOpacity="1"></stop>
                    <stop offset="0.875" stopColor="#11B9EC" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="#11BAED" stopOpacity="1"></stop>
                </linearGradient>
                <clipPath id="clip7">
                    <path d="M0 2h235v29H0zm0 0"></path>
                </clipPath>
                <clipPath id="clip8">
                    <path d="M0 16.828v14h117.5c70.5 0 117.5-.398 117.5-.898s-2.8-3.399-6.102-6.5C217.7 13.328 200.5 6.23 181.5 3.93c-5.898-.7-38.5-1.102-95.2-1.102H0zm0 0"></path>
                </clipPath>
                <linearGradient
                    id="linear3"
                    x1="0"
                    x2="235"
                    y1="15.471"
                    y2="15.471"
                    gradientTransform="translate(0 -.003)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#5650A2" stopOpacity="1"></stop>
                    <stop offset="0.063" stopColor="#5650A2" stopOpacity="1"></stop>
                    <stop offset="0.125" stopColor="#5551A3" stopOpacity="1"></stop>
                    <stop offset="0.188" stopColor="#5452A4" stopOpacity="1"></stop>
                    <stop offset="0.25" stopColor="#5453A4" stopOpacity="1"></stop>
                    <stop offset="0.313" stopColor="#5354A5" stopOpacity="1"></stop>
                    <stop offset="0.375" stopColor="#5255A6" stopOpacity="1"></stop>
                    <stop offset="0.438" stopColor="#5256A7" stopOpacity="1"></stop>
                    <stop offset="0.5" stopColor="#5157A7" stopOpacity="1"></stop>
                    <stop offset="0.563" stopColor="#5158A8" stopOpacity="1"></stop>
                    <stop offset="0.625" stopColor="#5059A9" stopOpacity="1"></stop>
                    <stop offset="0.688" stopColor="#4F5AA9" stopOpacity="1"></stop>
                    <stop offset="0.75" stopColor="#4F5BAA" stopOpacity="1"></stop>
                    <stop offset="0.813" stopColor="#4E5CAB" stopOpacity="1"></stop>
                    <stop offset="0.875" stopColor="#4D5DAB" stopOpacity="1"></stop>
                    <stop offset="0.938" stopColor="#4D5EAC" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="#4C5FAD" stopOpacity="1"></stop>
                </linearGradient>
                <clipPath id="clip9">
                    <path d="M528 2.828h234v14H528zm0 14h234v14H528zm0 0"></path>
                </clipPath>
                <linearGradient
                    id="linear4"
                    x1="528"
                    x2="762"
                    y1="15.471"
                    y2="15.471"
                    gradientTransform="translate(0 -.003)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#3F73BB" stopOpacity="1"></stop>
                    <stop offset="0.063" stopColor="#3F74BB" stopOpacity="1"></stop>
                    <stop offset="0.125" stopColor="#3E75BC" stopOpacity="1"></stop>
                    <stop offset="0.188" stopColor="#3D76BD" stopOpacity="1"></stop>
                    <stop offset="0.25" stopColor="#3D77BD" stopOpacity="1"></stop>
                    <stop offset="0.313" stopColor="#3C78BE" stopOpacity="1"></stop>
                    <stop offset="0.375" stopColor="#3B79BF" stopOpacity="1"></stop>
                    <stop offset="0.438" stopColor="#3B7ABF" stopOpacity="1"></stop>
                    <stop offset="0.5" stopColor="#3A7BC0" stopOpacity="1"></stop>
                    <stop offset="0.563" stopColor="#397BC1" stopOpacity="1"></stop>
                    <stop offset="0.625" stopColor="#397CC2" stopOpacity="1"></stop>
                    <stop offset="0.688" stopColor="#387DC2" stopOpacity="1"></stop>
                    <stop offset="0.75" stopColor="#387EC3" stopOpacity="1"></stop>
                    <stop offset="0.813" stopColor="#377FC4" stopOpacity="1"></stop>
                    <stop offset="0.875" stopColor="#3680C4" stopOpacity="1"></stop>
                    <stop offset="0.938" stopColor="#3681C5" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="#3582C6" stopOpacity="1"></stop>
                </linearGradient>
                <clipPath id="clip10">
                    <path d="M1141 2h231v29h-231zm0 0"></path>
                </clipPath>
                <clipPath id="clip11">
                    <path d="M1193.5 4.328c-21.7 3.402-35.898 10-47.5 21.902l-4.398 4.598 114.398-.2 114.5-.3.3-13.797.302-13.703-84.801.102c-63.5.101-86.801.398-92.801 1.398zm0 0"></path>
                </clipPath>
                <linearGradient
                    id="linear5"
                    x1="1141.602"
                    x2="1371.102"
                    y1="15.471"
                    y2="15.471"
                    gradientTransform="translate(0 -.003)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#249CD8" stopOpacity="1"></stop>
                    <stop offset="0.063" stopColor="#249DD8" stopOpacity="1"></stop>
                    <stop offset="0.125" stopColor="#239ED9" stopOpacity="1"></stop>
                    <stop offset="0.188" stopColor="#229EDA" stopOpacity="1"></stop>
                    <stop offset="0.25" stopColor="#229FDA" stopOpacity="1"></stop>
                    <stop offset="0.313" stopColor="#21A0DB" stopOpacity="1"></stop>
                    <stop offset="0.375" stopColor="#21A1DC" stopOpacity="1"></stop>
                    <stop offset="0.438" stopColor="#20A2DC" stopOpacity="1"></stop>
                    <stop offset="0.5" stopColor="#1FA3DD" stopOpacity="1"></stop>
                    <stop offset="0.563" stopColor="#1FA4DE" stopOpacity="1"></stop>
                    <stop offset="0.625" stopColor="#1EA5DE" stopOpacity="1"></stop>
                    <stop offset="0.688" stopColor="#1DA6DF" stopOpacity="1"></stop>
                    <stop offset="0.75" stopColor="#1DA7E0" stopOpacity="1"></stop>
                    <stop offset="0.813" stopColor="#1CA8E1" stopOpacity="1"></stop>
                    <stop offset="0.875" stopColor="#1CA9E1" stopOpacity="1"></stop>
                    <stop offset="0.938" stopColor="#1BAAE2" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="#1AABE3" stopOpacity="1"></stop>
                </linearGradient>
                <clipPath id="clip12">
                    <path d="M1709 2h94v29h-94zm0 0"></path>
                </clipPath>
                <clipPath id="clip13">
                    <path d="M1709 16.828v14h46.8l46.7-.098-6-5.601c-18.5-17.2-34.102-22.3-68.602-22.3H1709zm0 0"></path>
                </clipPath>
                <linearGradient
                    id="linear6"
                    x1="1709"
                    x2="1802.5"
                    y1="15.471"
                    y2="15.471"
                    gradientTransform="translate(0 -.003)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#0BC2F3" stopOpacity="1"></stop>
                    <stop offset="0.125" stopColor="#0BC2F3" stopOpacity="1"></stop>
                    <stop offset="0.25" stopColor="#0AC3F4" stopOpacity="1"></stop>
                    <stop offset="0.375" stopColor="#0AC4F4" stopOpacity="1"></stop>
                    <stop offset="0.5" stopColor="#09C5F5" stopOpacity="1"></stop>
                    <stop offset="0.625" stopColor="#09C5F5" stopOpacity="1"></stop>
                    <stop offset="0.75" stopColor="#08C6F6" stopOpacity="1"></stop>
                    <stop offset="0.875" stopColor="#08C7F6" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="#07C8F7" stopOpacity="1"></stop>
                </linearGradient>
                <clipPath id="clip14">
                    <path d="M1933 2.828h32v14h-32zm0 14h32v14h-32zm0 0"></path>
                </clipPath>
                <linearGradient
                    id="linear7"
                    x1="1933"
                    x2="1965"
                    y1="15.471"
                    y2="15.471"
                    gradientTransform="translate(0 -.003)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#01D1FD" stopOpacity="1"></stop>
                    <stop offset="0.25" stopColor="#01D1FE" stopOpacity="1"></stop>
                    <stop offset="0.5" stopColor="#01D2FE" stopOpacity="1"></stop>
                    <stop offset="0.75" stopColor="#01D2FE" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="#00D3FF" stopOpacity="1"></stop>
                </linearGradient>
                <clipPath id="clip15">
                    <path d="M0 60h254v138.93H0zm0 0"></path>
                </clipPath>
                <clipPath id="clip16">
                    <path d="M269 60h274v138.93H269zm0 0"></path>
                </clipPath>
                <clipPath id="clip17">
                    <path d="M629 60h32v138.93h-32zm0 0"></path>
                </clipPath>
                <clipPath id="clip18">
                    <path d="M747 60h274v138.93H747zm0 0"></path>
                </clipPath>
                <clipPath id="clip19">
                    <path d="M1124 60h249v138.93h-249zm0 0"></path>
                </clipPath>
                <clipPath id="clip20">
                    <path d="M1401 60h274v138.93h-274zm0 0"></path>
                </clipPath>
                <clipPath id="clip21">
                    <path d="M1709 60h32v138.93h-32zm0 0"></path>
                </clipPath>
                <clipPath id="clip22">
                    <path d="M1787 60h178v138.93h-178zm0 0"></path>
                </clipPath>
            </defs>
            <g transform="translate(0 -.001)">
                <g>
                    <g clipRule="nonzero">
                        <g clipPath="url(#clip1)">
                            <g clipPath="url(#clip2)" clipRule="nonzero">
                                <path
                                    fill="url(#linear0)"
                                    fillRule="nonzero"
                                    stroke="none"
                                    d="M354 0v30.93h105.2V0zm0 0"
                                ></path>
                            </g>
                        </g>
                        <g clipPath="url(#clip3)">
                            <g clipPath="url(#clip4)" clipRule="nonzero">
                                <path
                                    fill="url(#linear1)"
                                    fillRule="nonzero"
                                    stroke="none"
                                    d="M832 0v31.629h104V0zm0 0"
                                ></path>
                            </g>
                        </g>
                        <g clipPath="url(#clip5)" transform="translate(-72)">
                            <g clipPath="url(#clip6)" clipRule="nonzero">
                                <path
                                    fill="url(#linear2)"
                                    fillRule="nonzero"
                                    stroke="none"
                                    d="M1486 0v30.93h105.2V0zm0 0"
                                ></path>
                            </g>
                        </g>
                        <g clipPath="url(#clip7)">
                            <g clipPath="url(#clip8)" clipRule="nonzero">
                                <path
                                    fill="url(#linear3)"
                                    fillRule="nonzero"
                                    stroke="none"
                                    d="M0 2.828v28h235v-28zm0 0"
                                ></path>
                            </g>
                        </g>
                        <g clipPath="url(#clip9)">
                            <path
                                fill="url(#linear4)"
                                fillRule="nonzero"
                                stroke="none"
                                d="M528 2.828v28h234v-28zm0 0"
                            ></path>
                        </g>
                        <g clipPath="url(#clip10)" transform="translate(-72)">
                            <g clipPath="url(#clip11)" clipRule="nonzero">
                                <path
                                    fill="url(#linear5)"
                                    fillRule="nonzero"
                                    stroke="none"
                                    d="M1141.602 2.828v28h229.5v-28zm0 0"
                                ></path>
                            </g>
                        </g>
                        <g clipPath="url(#clip12)" transform="translate(-72)">
                            <g clipPath="url(#clip13)" clipRule="nonzero">
                                <path
                                    fill="url(#linear6)"
                                    fillRule="nonzero"
                                    stroke="none"
                                    d="M1709 2.828v28h93.5v-28zm0 0"
                                ></path>
                            </g>
                        </g>
                        <g clipPath="url(#clip14)" transform="translate(-72)">
                            <path
                                fill="url(#linear7)"
                                fillRule="nonzero"
                                stroke="none"
                                d="M1933 2.828v28h32v-28zm0 0"
                            ></path>
                        </g>
                        <g fill="#fff" clipPath="url(#clip15)">
                            <path
                                fill="#fff"
                                fillOpacity="1"
                                fillRule="nonzero"
                                stroke="none"
                                d="M0 129.828v69.102l92.8-.301 92.7-.3 10-2.798c15.8-4.203 25.5-9.601 35-19.101 9-9.102 13.7-17 17.898-30.399 6.301-20.101 7.5-56 2.5-80l-1-5.203h-17.097c-15.801 0-17 .102-16.301 1.703 5.8 15.098 6.8 51.598 1.898 70.598-3.5 13.7-11.097 23.3-22.796 28.8-12.204 5.7-14.903 5.9-92.704 5.9H32v-107H0zm0 0"
                            ></path>
                        </g>
                        <g fill="#fff" clipPath="url(#clip16)">
                            <path
                                fill="#fff"
                                fillOpacity="1"
                                fillRule="nonzero"
                                stroke="none"
                                d="M303.5 129.828l-34.5 69h34l13.2-26.5 13.3-26.5 77 .203 77 .297 13 26.203 13 26.297h16.8c9.2 0 16.7-.2 16.7-.5 0-.297-15.398-31.297-34.2-69l-34.3-68.5h-16.7c-9.3 0-16.8.3-16.8.602 0 .3 6.102 13 13.5 28.101 7.398 15.2 13.5 27.797 13.5 28 0 .098-27.898.297-62.102.297-34.097 0-61.898-.297-61.699-.797.2-.402 6.5-13 14.102-27.902C365.8 74.23 372 61.73 372 61.429c0-.3-7.602-.6-17-.6h-17zm0 0"
                            ></path>
                        </g>
                        <g fill="#fff" clipPath="url(#clip17)">
                            <path
                                fill="#fff"
                                fillOpacity="1"
                                fillRule="nonzero"
                                stroke="none"
                                d="M629 129.828v69h32v-138h-32zm0 0"
                            ></path>
                        </g>
                        <g fill="#fff" clipPath="url(#clip18)">
                            <path
                                fill="#fff"
                                fillOpacity="1"
                                fillRule="nonzero"
                                stroke="none"
                                d="M815.2 62.031c-1.098 1.399-68.2 135.5-68.2 136.297 0 .3 7.5.5 16.8.5h16.7l13.3-26.5 13.2-26.5h154l13.2 26.5 13.3 26.5h16.7c9.3 0 16.8-.2 16.8-.5 0-.297-15.398-31.297-34.2-69l-34.3-68.5H918l14 28c7.7 15.402 14 28.203 14 28.5 0 .3-27.898.5-62 .5-34.102 0-62-.2-62-.5 0-.297 6.3-13.098 14-28.5l14-28h-16.898c-12.301 0-17.102.3-17.903 1.203zm0 0"
                            ></path>
                        </g>
                        <g fill="#fff" clipPath="url(#clip19)" transform="translate(-72)">
                            <path
                                fill="#fff"
                                fillOpacity="1"
                                fillRule="nonzero"
                                stroke="none"
                                d="M1127.5 62.73c-1.602 5.098-3.5 25.098-3.5 37.598 0 24.8 3.602 44.102 11.2 59.402 3.3 6.7 6 10.399 12.3 16.7 9.5 9.5 19.2 14.898 35 19.101l10 2.797 90.3.3 90.2.302V96.828h-32v71.102l-72.2-.301-72.3-.3-7.8-2.7c-16.302-5.8-24.5-14.399-29.098-30.8-4.903-17.4-4.704-50.9.398-67.298l1.8-5.703H1145c-15.7 0-16.898.102-17.5 1.902zm0 0"
                            ></path>
                        </g>
                        <g fill="#fff" clipPath="url(#clip20)" transform="translate(-72)">
                            <path
                                fill="#fff"
                                fillOpacity="1"
                                fillRule="nonzero"
                                stroke="none"
                                d="M1435.5 129.828l-34.5 69h34l13.2-26.5 13.3-26.5H1615l13.2 26.5 13.3 26.5h16.7c9.3 0 16.8-.2 16.8-.5 0-.297-15.398-31.297-34.2-69l-34.3-68.5h-16.7c-10.6 0-16.8.402-16.8 1 0 .602 6.102 13.203 13.5 28 7.398 14.8 13.5 27.203 13.5 27.5 0 .3-27.898.5-62 .5-34.102 0-62-.2-62-.5 0-.297 6.3-13.098 14-28.5l14-28h-34zm0 0"
                            ></path>
                        </g>
                        <g fill="#fff" clipPath="url(#clip21)" transform="translate(-72)">
                            <path
                                fill="#fff"
                                fillOpacity="1"
                                fillRule="nonzero"
                                stroke="none"
                                d="M1709 129.828v69h32v-138h-32zm0 0"
                            ></path>
                        </g>
                        <g fill="#fff" clipPath="url(#clip22)" transform="translate(-72)">
                            <path
                                fill="#fff"
                                fillOpacity="1"
                                fillRule="nonzero"
                                stroke="none"
                                d="M1787 61.43c0 .3 2.602 3.8 5.8 7.699 3.2 4 17 21.8 30.7 39.7 40 52.5 53.898 68.1 68.898 77.901 15 9.7 25.301 12.098 53.204 12.098H1965v-138h-32v107l-5.7-.598c-21.6-2.199-25.698-6.3-76.5-75l-23.198-31.402H1807.3c-11.2 0-20.301.3-20.301.602zm0 0"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default WhiteLogo;
