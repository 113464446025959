import React from 'react';
import {DataGrid, GridColDef, GridOverlay, GridRenderCellParams, GridToolbarContainer} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import {Grid, LinearProgress, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {GridRTL} from "../generic/GenericGridRTL";
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import {Constants} from "../../utils/Constants";
import GenericIconAction from "../generic/GenericIconAction";
import OpenIcon from "../customIcons/OpenIcon";


const CustomToolbar = ({refreshProjects, openNewProject}) => {
    return (
        <GridToolbarContainer>
            <Grid container alignItems={"center"}>
                <Grid item xs={4}>
                    <Typography variant="h4" component="h2" style={{padding: ".7rem"}}>
                        Projects List
                    </Typography>
                </Grid>
                <GridRTL item xs={8} dir={"rtl"}>
                    <Button onClick={() => openNewProject()} variant={"contained"}>Add project</Button>
                    <Button onClick={() => refreshProjects()} variant={"outlined"}>Refresh projects</Button>
                </GridRTL>
            </Grid>
        </GridToolbarContainer>
    )
}

export function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{position: 'absolute', top: 0, width: '100%'}}>
                <LinearProgress/>
            </div>
        </GridOverlay>
    );
}


const ProjectList = ({projects, refreshProjects, loading, openNewProject, openEditProject, setSelectedProject}) => {

    const navigate = useNavigate();

    const columns: GridColDef[] = [
        {field: 'name', headerName: 'Name', width: 150},
        {field: 'description', headerName: 'Description', flex: 1},
        {
            field: 'done', headerName: "", width: 200, renderCell: (params: GridRenderCellParams) => {
                return (
                    <Grid container alignItems={"center"} justifyContent={"center"}>

                        <GridRTL item xs={8} dir={"rtl"}>
                            <GenericIconAction text={"Edit Project"} icon={<BuildCircleIcon fontSize="large" color={"primary"}/>}
                                               tooltipDirection={Constants.TOOLTIP.PLACEMENTS.TOP}
                                               disableTooltip={false} color={"primary"} action={()=>openEditProject()}/>
                            <GenericIconAction text={"Open Project"} icon={<OpenIcon/>}
                                               tooltipDirection={Constants.TOOLTIP.PLACEMENTS.TOP}
                                               disableTooltip={false} color={"primary"} action={()=>{navigate("" + params.row.id)}}/>
                        </GridRTL>
                    </Grid>
                )
            }
        }
    ];
    const [selectionModel, setSelectionModel] = React.useState<any[]>([]);

    return (
        <DataGrid columns={columns} rows={projects}
                  components={{Toolbar: CustomToolbar, LoadingOverlay: CustomLoadingOverlay}}
                  onSelectionModelChange={(newSelectionModel) => {
                      setSelectionModel(newSelectionModel);
                      for (let x of projects)
                          if (x?.id === newSelectionModel[0]) {
                              setSelectedProject(x);
                          }
                  }}
                  selectionModel={selectionModel}
                  loading={loading}
                  componentsProps={{
                      toolbar: {
                          refreshProjects: refreshProjects,
                          openNewProject: openNewProject,
                          openEditProject: openEditProject,
                          isSomethingSelected: selectionModel[0]
                      }
                  }}
        />
    );
};

export default ProjectList;
