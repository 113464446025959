import CommonTokenStream from "antlr4"
import InputStream from "antlr4"

import AceErrorListener from "./AceErrorListener";

const Parser = require("../../antlr/PostgreSQLParser").PostgreSQLParser;

const Lexer = require("../../antlr/PostgreSQLLexer").PostgreSQLLexer;

function parse(code: string) {
    const inputStream = new InputStream(code);
    const lexer = new Lexer(inputStream);
    let errors = [];
    const todoLangErrorsListner = new AceErrorListener(errors);
    const tokenStream = new CommonTokenStream(lexer);
    const parser = new Parser(tokenStream);
    parser.removeErrorListeners();
    parser.addErrorListener(todoLangErrorsListner);
    parser.buildParseTree = true;
    try {
        const ast =  parser.root();
        return {ast, errors};
    }catch (e : any) {
        return {errors}
    }
}
export function parseAndGetASTRoot(code: string) {
    const {ast} = parse(code);
    return ast;
}
export function parseAndGetSyntaxErrors(code: string) {
    try{
        const {errors} = parse(code);
        return errors;
    }catch (e : any){
        return [{
            row: 0,
            column: 1,
            text: e,
            type: "error",
            name: "missingDot"
        }]
    }
}
