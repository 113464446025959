import * as React from 'react';
import {useContext, useState} from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {ButtonGroup, IconButton, InputAdornment, Link, TextField} from "@mui/material";
import {NavLink, useNavigate} from "react-router-dom"
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {LoginRequest} from "../classes/model/LoginRequest";
import AuthService from "../utils/services/auth.service";
import {LoadingButton} from "@mui/lab";
import {AlertContext} from "./generic/AlertContext";
import {Constants} from "../utils/Constants";
import ReCAPTCHA from "react-google-recaptcha";

export default function LoginCard() {

    const {setContext} = useContext(AlertContext);

    const [loginRequest, setLoginRequest] = useState(new LoginRequest());
    const [showPassword, setShowPassword] = useState(false);
    //Loading button prop
    const [requesting, setRequesting] = useState(false);

    const navigate = useNavigate();
    const recaptchaRef = React.createRef<ReCAPTCHA>();

    const onSubmitWithReCAPTCHA = async () => {
        if (recaptchaRef.current) {
            const token = await recaptchaRef.current.executeAsync();
            if(token){
                login()
            }
        }
    }

    function login() {
        setRequesting(true);
        AuthService.login(loginRequest).then(
            () => {
                setRequesting(false)
                navigate("/dashboard", {replace: true});
            },
            error => {
                setContext({
                    text: Constants.MESSAGES.ERROR_TITLE,
                    content: Constants.MESSAGES.LOGIN_ERROR_CONTENT,
                    severity: Constants.ALERT_SEVERITY.ERROR,
                    open: true
                })
                setRequesting(false);
            }
        );
    }

    const handleTogglePassword = () => setShowPassword(showPassword => !showPassword);

    const handleKeyUp = e => {
        if (e.key === 'Enter' && loginRequest.email !== '' && loginRequest.password !== '') {
            onSubmitWithReCAPTCHA();
        }
    }

    return (
        <div>
            <Card sx={{display: "flex", flexWrap: "wrap"}} onKeyUp={handleKeyUp}>
                <CardContent sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "space-around",
                    justifyContent: "space-around"
                }}>
                    <Typography sx={{fontSize: 36, fontWeight: "bold"}} color="text.primary" gutterBottom>
                        Welcome
                    </Typography>
                    <TextField label="Email" variant="outlined" value={loginRequest.email}
                               style={{marginBottom: "20px"}}
                               onChange={(e) => setLoginRequest({...loginRequest, email: e.target.value})}/>
                    {/*FIXME: double eye on Microsoft Edge when password*/}

                    <TextField type={showPassword ? "text" : "password"} label="Password" variant="outlined"
                               value={loginRequest.password}
                               onChange={(e) => setLoginRequest({...loginRequest, password: e.target.value})}
                               InputProps={{
                                   endAdornment: (
                                       <InputAdornment position={"end"}>
                                           <IconButton
                                               aria-label="toggle password visibility"
                                               onClick={handleTogglePassword}
                                           >
                                               {showPassword ? <Visibility/> : <VisibilityOff/>}
                                           </IconButton>
                                       </InputAdornment>
                                   )
                               }}/>

                </CardContent>
                <CardActions
                    sx={{width: "100%", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                    {!requesting && <Button sx={{width: "97%"}} variant="contained" onClick={onSubmitWithReCAPTCHA}>Login</Button>}
                    {requesting && <LoadingButton loading sx={{width: "97%"}} variant="outlined">
                        Submit
                    </LoadingButton>}
                    <ButtonGroup sx={{
                        display: "flex",
                        width: "97%",
                        textAlign: "center"
                    }} orientation="vertical">
                        <Link component={NavLink}
                              sx={{width: "100%", mt: "20px !important", mb: "20px !important"}}
                              to={"/forgot-password"}>Forgot your password? Click here</Link>
                    </ButtonGroup>
                </CardActions>
            </Card>
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                theme="dark"
                style={{position: "relative", zIndex: 5}}
                sitekey={Constants.CAPTCHA_SITE_KEY}
            />
        </div>
    );
}
