import {Skeleton, Stack} from '@mui/material';
import React from 'react';

const ProfileSkeleton = () => {
    return (
        <>
            <Stack spacing={1}>
                <Skeleton variant="text" />
                <Skeleton variant="circular" width={80} height={80} />
                <Skeleton variant="rectangular" width={420} height={236} />
            </Stack>
        </>
    );
};

export default ProfileSkeleton;
