import * as React from 'react';
import Box from "@mui/material/Box";
import ForgotPasswordCard from "../components/ForgotPasswordCard";
import HeaderBar from "../components/HeaderBar";
import {styled} from "@mui/material/styles";


function ForgotPasswordPage({mode, setMode}) {

    const CustomBox = styled('div')(({theme}) => ({
        root: {
            [theme.breakpoints.up('md')]: {
                width: '30%'
            },
            [theme.breakpoints.down('md')]: {
                width: '50%'
            },
            [theme.breakpoints.down('sm')]: {
                width: '60%'
            }
        }
    }));

    return (
        <>
            <HeaderBar mode={mode} setMode={setMode}/>
            <Box sx={{
                display: "flex",
                mt: "8%",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                alignSelf: "center",
            }}>
                <CustomBox>
                    <ForgotPasswordCard/>
                </CustomBox>
            </Box>
        </>
    );
}

export default ForgotPasswordPage;
