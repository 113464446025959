import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from 'react';
import {Button, Checkbox, FormControlLabel, FormGroup, Grid, TextField, Typography} from "@mui/material";
import User from "../classes/model/User";
import AuthService from "../utils/services/auth.service";
import {AlertContext} from "./generic/AlertContext";
import {Constants} from "../utils/Constants";

interface ProfileProps {
    user: User;
    toggle: boolean;
    setToggle: Dispatch<SetStateAction<boolean>>;
}

const ProfileComponent = ({user, toggle, setToggle}: ProfileProps) => {

    const {setContext} = useContext(AlertContext);

    const [modified, setModified] = useState(false)
    const [visualizedUser, setVisualizedUser] = useState(user);


    function sameUser() {
        setModified(!(user.firstName === visualizedUser.firstName && user.lastName === visualizedUser.lastName));
    }

    function updateUser() {
        const {firstName, lastName, email} = visualizedUser;
        AuthService.updateUser(
            {firstName, lastName, email}
        ).then(() => {
            setModified(false);
            setContext({
                        text: Constants.MESSAGES.SUCCESS_TITLE,
                        content: Constants.MESSAGES.SUCCESS_CONTENT,
                        severity: Constants.ALERT_SEVERITY.SUCCESS,
                        open: true
                    })
            setToggle(!toggle);
        }, error => {
            setContext({
                        text: Constants.MESSAGES.ERROR_TITLE,
                        content: Constants.MESSAGES.ERROR_CONTENT,
                        severity: Constants.ALERT_SEVERITY.ERROR,
                        open: true
                    })
            setToggle(!toggle);
        });
    }

    useEffect(() => {
        sameUser()
    }, [visualizedUser])


    return (
        <>
            <Grid container columns={12} sx={{padding: ".7rem"}}>
                <Grid item xs={12} container alignItems={"start"} justifyContent={"start"}>
                    <Grid item xs={12}>
                        <Typography variant="h4" component="h2">
                            Profile page
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} container alignItems={"start"} justifyContent={"center"}>
                    <Grid item xs={8} container columns={12} alignItems={"center"} justifyContent={"center"} rowSpacing={5}>
                        <Grid item xs={8}>
                            <TextField fullWidth value={visualizedUser.firstName} label={"First name"}
                                       onChange={(e) => {
                                           setVisualizedUser({...visualizedUser, firstName: e.target.value});
                                       }}/>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField fullWidth value={visualizedUser.lastName} label={"Last name"}
                                       onChange={(e) => {
                                           setVisualizedUser({...visualizedUser, lastName: e.target.value});
                                       }}/>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField fullWidth disabled value={visualizedUser.email} label={"Email"}/>
                        </Grid>
                        <Grid item xs={8}>
                            <FormGroup>
                                <FormControlLabel control={
                                    <Checkbox checked={!!visualizedUser.mailVerified}
                                              sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}/>}
                                                  label="Mail Verified"/>
                            </FormGroup>
                        </Grid>
                        <Grid item container xs={8} alignItems={"end"} justifyContent={"flex-end"}>
                            <Button variant="contained" disabled={!modified} onClick={updateUser}>UPDATE</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default ProfileComponent;
