import * as React from 'react';
import {useContext, useState} from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {ButtonGroup, Link, TextField} from "@mui/material";
import {NavLink} from "react-router-dom";
import AuthService from "../utils/services/auth.service";
import {LoadingButton} from "@mui/lab";
import {AlertContext} from "./generic/AlertContext";
import {Constants} from "../utils/Constants";
import ReCAPTCHA from "react-google-recaptcha";

export default function ForgotPasswordCard() {

    const {setContext} = useContext(AlertContext);

    const [email, setEmail] = useState("");
    const [requesting, setRequesting] = useState(false);

    const recaptchaRef = React.createRef<ReCAPTCHA>();

    const onSubmitWithReCAPTCHA = async () => {
        if (recaptchaRef.current) {
            const token = await recaptchaRef.current.executeAsync();
            if(token){
                forgotPassword()
            }
        }
    }

    function forgotPassword() {
        setRequesting(true);
        AuthService.resetPassword(email).then(
            () => {
                setContext({
                        text: Constants.MESSAGES.SUCCESS_TITLE,
                        content: Constants.MESSAGES.FORGOT_PSW_SUCCESS_CONTENT,
                        severity: Constants.ALERT_SEVERITY.SUCCESS,
                        open: true
                    })
                setRequesting(false)
            },
            error => {
                setContext({
                        text: Constants.MESSAGES.ERROR_TITLE,
                        content: Constants.MESSAGES.FORGOT_PSW_ERROR_CONTENT,
                        severity: Constants.ALERT_SEVERITY.ERROR,
                        open: true
                    })
                setRequesting(false)
            });
    }

    const handleKeyUp = e => {
        if (e.key === 'Enter') {
            onSubmitWithReCAPTCHA();
        }
    }

    return (
        <div>
            <Card sx={{minWidth: 275, display: "flex", flexWrap: "wrap"}} onKeyUp={handleKeyUp}>
                <CardContent sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "space-around",
                    justifyContent: "space-around"
                }}>
                    <Typography sx={{fontSize: 36, fontWeight: "bold"}} color="text.primary" gutterBottom>
                        Reset Password
                    </Typography>
                    <TextField label="Email" variant="outlined" value={email}
                               onChange={(e) => setEmail(e.target.value)}/>
                </CardContent>
                <CardActions sx={{width: "100%", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                    {!requesting &&
                    <Button sx={{width: "97%"}} variant="contained" onClick={onSubmitWithReCAPTCHA}>GET NEW PASSWORD</Button>}
                    {requesting && <LoadingButton loading sx={{width: "97%"}} variant="outlined">Submit</LoadingButton>}
                    <ButtonGroup sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "space-between",
                        justifyContent: "space-between",
                        width: "97%",
                        textAlign: "center"
                    }}>
                        <Link component={NavLink}
                              sx={{width: "100%", mt: "20px !important", mb: "20px !important"}}
                              to={"/login"}>Already have an account? Log in</Link>
                    </ButtonGroup>
                </CardActions>
            </Card>
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                theme="dark"
                style={{position: "relative", zIndex: 5}}
                sitekey={Constants.CAPTCHA_SITE_KEY}
            />
        </div>
    );
}
