// Generated from PostgreSQLParser.g4 by ANTLR 4.8
// jshint ignore: start
/* eslint-disable */
var antlr4 = require('antlr4').default;


// This class defines a complete listener for a parse tree produced by PostgreSQLParser.
function PostgreSQLParserListener() {
	antlr4.tree.ParseTreeListener.call(this);
	return this;
}

PostgreSQLParserListener.prototype = Object.create(antlr4.tree.ParseTreeListener.prototype);
PostgreSQLParserListener.prototype.constructor = PostgreSQLParserListener;

// Enter a parse tree produced by PostgreSQLParser#root.
PostgreSQLParserListener.prototype.enterRoot = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#root.
PostgreSQLParserListener.prototype.exitRoot = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#stmt.
PostgreSQLParserListener.prototype.enterStmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#stmt.
PostgreSQLParserListener.prototype.exitStmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#abort_stmt.
PostgreSQLParserListener.prototype.enterAbort_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#abort_stmt.
PostgreSQLParserListener.prototype.exitAbort_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_stmt.
PostgreSQLParserListener.prototype.enterAlter_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_stmt.
PostgreSQLParserListener.prototype.exitAlter_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_aggregate_stmt.
PostgreSQLParserListener.prototype.enterAlter_aggregate_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_aggregate_stmt.
PostgreSQLParserListener.prototype.exitAlter_aggregate_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_collation_stmt.
PostgreSQLParserListener.prototype.enterAlter_collation_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_collation_stmt.
PostgreSQLParserListener.prototype.exitAlter_collation_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_conversion_stmt.
PostgreSQLParserListener.prototype.enterAlter_conversion_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_conversion_stmt.
PostgreSQLParserListener.prototype.exitAlter_conversion_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_database_stmt.
PostgreSQLParserListener.prototype.enterAlter_database_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_database_stmt.
PostgreSQLParserListener.prototype.exitAlter_database_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_default_privileges_stmt.
PostgreSQLParserListener.prototype.enterAlter_default_privileges_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_default_privileges_stmt.
PostgreSQLParserListener.prototype.exitAlter_default_privileges_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_domain_stmt.
PostgreSQLParserListener.prototype.enterAlter_domain_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_domain_stmt.
PostgreSQLParserListener.prototype.exitAlter_domain_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_event_trigger_stmt.
PostgreSQLParserListener.prototype.enterAlter_event_trigger_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_event_trigger_stmt.
PostgreSQLParserListener.prototype.exitAlter_event_trigger_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_extension_stmt.
PostgreSQLParserListener.prototype.enterAlter_extension_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_extension_stmt.
PostgreSQLParserListener.prototype.exitAlter_extension_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_foreign_data_wrapper_stmt.
PostgreSQLParserListener.prototype.enterAlter_foreign_data_wrapper_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_foreign_data_wrapper_stmt.
PostgreSQLParserListener.prototype.exitAlter_foreign_data_wrapper_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_foreign_table_action.
PostgreSQLParserListener.prototype.enterAlter_foreign_table_action = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_foreign_table_action.
PostgreSQLParserListener.prototype.exitAlter_foreign_table_action = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_foreign_table_action_list.
PostgreSQLParserListener.prototype.enterAlter_foreign_table_action_list = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_foreign_table_action_list.
PostgreSQLParserListener.prototype.exitAlter_foreign_table_action_list = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_foreign_table_stmt.
PostgreSQLParserListener.prototype.enterAlter_foreign_table_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_foreign_table_stmt.
PostgreSQLParserListener.prototype.exitAlter_foreign_table_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_function_stmt.
PostgreSQLParserListener.prototype.enterAlter_function_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_function_stmt.
PostgreSQLParserListener.prototype.exitAlter_function_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_group_stmt.
PostgreSQLParserListener.prototype.enterAlter_group_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_group_stmt.
PostgreSQLParserListener.prototype.exitAlter_group_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_index_stmt.
PostgreSQLParserListener.prototype.enterAlter_index_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_index_stmt.
PostgreSQLParserListener.prototype.exitAlter_index_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_language_stmt.
PostgreSQLParserListener.prototype.enterAlter_language_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_language_stmt.
PostgreSQLParserListener.prototype.exitAlter_language_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_large_object_stmt.
PostgreSQLParserListener.prototype.enterAlter_large_object_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_large_object_stmt.
PostgreSQLParserListener.prototype.exitAlter_large_object_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_materialize_view_stmt.
PostgreSQLParserListener.prototype.enterAlter_materialize_view_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_materialize_view_stmt.
PostgreSQLParserListener.prototype.exitAlter_materialize_view_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_operator_stmt.
PostgreSQLParserListener.prototype.enterAlter_operator_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_operator_stmt.
PostgreSQLParserListener.prototype.exitAlter_operator_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_operator_class_stmt.
PostgreSQLParserListener.prototype.enterAlter_operator_class_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_operator_class_stmt.
PostgreSQLParserListener.prototype.exitAlter_operator_class_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_operator_family_stmt.
PostgreSQLParserListener.prototype.enterAlter_operator_family_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_operator_family_stmt.
PostgreSQLParserListener.prototype.exitAlter_operator_family_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_policy_stmt.
PostgreSQLParserListener.prototype.enterAlter_policy_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_policy_stmt.
PostgreSQLParserListener.prototype.exitAlter_policy_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_publication_stmt.
PostgreSQLParserListener.prototype.enterAlter_publication_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_publication_stmt.
PostgreSQLParserListener.prototype.exitAlter_publication_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_role_options.
PostgreSQLParserListener.prototype.enterAlter_role_options = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_role_options.
PostgreSQLParserListener.prototype.exitAlter_role_options = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_role_stmt.
PostgreSQLParserListener.prototype.enterAlter_role_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_role_stmt.
PostgreSQLParserListener.prototype.exitAlter_role_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_rule_stmt.
PostgreSQLParserListener.prototype.enterAlter_rule_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_rule_stmt.
PostgreSQLParserListener.prototype.exitAlter_rule_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_schema_stmt.
PostgreSQLParserListener.prototype.enterAlter_schema_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_schema_stmt.
PostgreSQLParserListener.prototype.exitAlter_schema_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_sequence_stmt.
PostgreSQLParserListener.prototype.enterAlter_sequence_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_sequence_stmt.
PostgreSQLParserListener.prototype.exitAlter_sequence_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_server_options_list.
PostgreSQLParserListener.prototype.enterAlter_server_options_list = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_server_options_list.
PostgreSQLParserListener.prototype.exitAlter_server_options_list = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_server_stmt.
PostgreSQLParserListener.prototype.enterAlter_server_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_server_stmt.
PostgreSQLParserListener.prototype.exitAlter_server_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_statistics_stmt.
PostgreSQLParserListener.prototype.enterAlter_statistics_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_statistics_stmt.
PostgreSQLParserListener.prototype.exitAlter_statistics_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_subscription_stmt.
PostgreSQLParserListener.prototype.enterAlter_subscription_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_subscription_stmt.
PostgreSQLParserListener.prototype.exitAlter_subscription_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_system_stmt.
PostgreSQLParserListener.prototype.enterAlter_system_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_system_stmt.
PostgreSQLParserListener.prototype.exitAlter_system_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_table_stmt.
PostgreSQLParserListener.prototype.enterAlter_table_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_table_stmt.
PostgreSQLParserListener.prototype.exitAlter_table_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_tablespace_stmt.
PostgreSQLParserListener.prototype.enterAlter_tablespace_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_tablespace_stmt.
PostgreSQLParserListener.prototype.exitAlter_tablespace_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_text_search_config_stmt.
PostgreSQLParserListener.prototype.enterAlter_text_search_config_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_text_search_config_stmt.
PostgreSQLParserListener.prototype.exitAlter_text_search_config_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_text_search_dict_stmt.
PostgreSQLParserListener.prototype.enterAlter_text_search_dict_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_text_search_dict_stmt.
PostgreSQLParserListener.prototype.exitAlter_text_search_dict_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_text_search_parser_stmt.
PostgreSQLParserListener.prototype.enterAlter_text_search_parser_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_text_search_parser_stmt.
PostgreSQLParserListener.prototype.exitAlter_text_search_parser_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_text_search_template_stmt.
PostgreSQLParserListener.prototype.enterAlter_text_search_template_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_text_search_template_stmt.
PostgreSQLParserListener.prototype.exitAlter_text_search_template_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_trigger_stmt.
PostgreSQLParserListener.prototype.enterAlter_trigger_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_trigger_stmt.
PostgreSQLParserListener.prototype.exitAlter_trigger_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_type_stmt.
PostgreSQLParserListener.prototype.enterAlter_type_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_type_stmt.
PostgreSQLParserListener.prototype.exitAlter_type_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_user_stmt.
PostgreSQLParserListener.prototype.enterAlter_user_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_user_stmt.
PostgreSQLParserListener.prototype.exitAlter_user_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_user_mapping_stmt.
PostgreSQLParserListener.prototype.enterAlter_user_mapping_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_user_mapping_stmt.
PostgreSQLParserListener.prototype.exitAlter_user_mapping_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alter_view_stmt.
PostgreSQLParserListener.prototype.enterAlter_view_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alter_view_stmt.
PostgreSQLParserListener.prototype.exitAlter_view_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#analyze_stmt.
PostgreSQLParserListener.prototype.enterAnalyze_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#analyze_stmt.
PostgreSQLParserListener.prototype.exitAnalyze_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#close_stmt.
PostgreSQLParserListener.prototype.enterClose_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#close_stmt.
PostgreSQLParserListener.prototype.exitClose_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#cluster_stmt.
PostgreSQLParserListener.prototype.enterCluster_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#cluster_stmt.
PostgreSQLParserListener.prototype.exitCluster_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#comment_stmt.
PostgreSQLParserListener.prototype.enterComment_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#comment_stmt.
PostgreSQLParserListener.prototype.exitComment_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#commit_stmt.
PostgreSQLParserListener.prototype.enterCommit_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#commit_stmt.
PostgreSQLParserListener.prototype.exitCommit_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#commit_prepared_stmt.
PostgreSQLParserListener.prototype.enterCommit_prepared_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#commit_prepared_stmt.
PostgreSQLParserListener.prototype.exitCommit_prepared_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#copy_stmt.
PostgreSQLParserListener.prototype.enterCopy_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#copy_stmt.
PostgreSQLParserListener.prototype.exitCopy_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_stmt.
PostgreSQLParserListener.prototype.enterCreate_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_stmt.
PostgreSQLParserListener.prototype.exitCreate_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_access_method_stmt.
PostgreSQLParserListener.prototype.enterCreate_access_method_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_access_method_stmt.
PostgreSQLParserListener.prototype.exitCreate_access_method_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_aggregate_stmt.
PostgreSQLParserListener.prototype.enterCreate_aggregate_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_aggregate_stmt.
PostgreSQLParserListener.prototype.exitCreate_aggregate_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_cast_stmt.
PostgreSQLParserListener.prototype.enterCreate_cast_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_cast_stmt.
PostgreSQLParserListener.prototype.exitCreate_cast_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_collation_opt.
PostgreSQLParserListener.prototype.enterCreate_collation_opt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_collation_opt.
PostgreSQLParserListener.prototype.exitCreate_collation_opt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_collation_opt_list.
PostgreSQLParserListener.prototype.enterCreate_collation_opt_list = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_collation_opt_list.
PostgreSQLParserListener.prototype.exitCreate_collation_opt_list = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_collation_stmt.
PostgreSQLParserListener.prototype.enterCreate_collation_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_collation_stmt.
PostgreSQLParserListener.prototype.exitCreate_collation_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_conversion_stmt.
PostgreSQLParserListener.prototype.enterCreate_conversion_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_conversion_stmt.
PostgreSQLParserListener.prototype.exitCreate_conversion_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_database_stmt.
PostgreSQLParserListener.prototype.enterCreate_database_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_database_stmt.
PostgreSQLParserListener.prototype.exitCreate_database_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#domain_constraint.
PostgreSQLParserListener.prototype.enterDomain_constraint = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#domain_constraint.
PostgreSQLParserListener.prototype.exitDomain_constraint = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_domain_stmt.
PostgreSQLParserListener.prototype.enterCreate_domain_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_domain_stmt.
PostgreSQLParserListener.prototype.exitCreate_domain_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_event_trigger_cond.
PostgreSQLParserListener.prototype.enterCreate_event_trigger_cond = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_event_trigger_cond.
PostgreSQLParserListener.prototype.exitCreate_event_trigger_cond = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_event_trigger_stmt.
PostgreSQLParserListener.prototype.enterCreate_event_trigger_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_event_trigger_stmt.
PostgreSQLParserListener.prototype.exitCreate_event_trigger_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_foreign_data_options.
PostgreSQLParserListener.prototype.enterCreate_foreign_data_options = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_foreign_data_options.
PostgreSQLParserListener.prototype.exitCreate_foreign_data_options = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_foreign_data_stmt.
PostgreSQLParserListener.prototype.enterCreate_foreign_data_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_foreign_data_stmt.
PostgreSQLParserListener.prototype.exitCreate_foreign_data_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_foreign_table_stmt.
PostgreSQLParserListener.prototype.enterCreate_foreign_table_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_foreign_table_stmt.
PostgreSQLParserListener.prototype.exitCreate_foreign_table_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_function_stmt.
PostgreSQLParserListener.prototype.enterCreate_function_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_function_stmt.
PostgreSQLParserListener.prototype.exitCreate_function_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_group_stmt.
PostgreSQLParserListener.prototype.enterCreate_group_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_group_stmt.
PostgreSQLParserListener.prototype.exitCreate_group_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_index_stmt.
PostgreSQLParserListener.prototype.enterCreate_index_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_index_stmt.
PostgreSQLParserListener.prototype.exitCreate_index_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_language_stmt.
PostgreSQLParserListener.prototype.enterCreate_language_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_language_stmt.
PostgreSQLParserListener.prototype.exitCreate_language_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_materialized_view_stmt.
PostgreSQLParserListener.prototype.enterCreate_materialized_view_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_materialized_view_stmt.
PostgreSQLParserListener.prototype.exitCreate_materialized_view_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_operator_stmt.
PostgreSQLParserListener.prototype.enterCreate_operator_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_operator_stmt.
PostgreSQLParserListener.prototype.exitCreate_operator_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_operator_class_opt.
PostgreSQLParserListener.prototype.enterCreate_operator_class_opt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_operator_class_opt.
PostgreSQLParserListener.prototype.exitCreate_operator_class_opt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_operator_class_stmt.
PostgreSQLParserListener.prototype.enterCreate_operator_class_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_operator_class_stmt.
PostgreSQLParserListener.prototype.exitCreate_operator_class_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_operator_family_stmt.
PostgreSQLParserListener.prototype.enterCreate_operator_family_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_operator_family_stmt.
PostgreSQLParserListener.prototype.exitCreate_operator_family_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_policy_stmt.
PostgreSQLParserListener.prototype.enterCreate_policy_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_policy_stmt.
PostgreSQLParserListener.prototype.exitCreate_policy_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_role_stmt.
PostgreSQLParserListener.prototype.enterCreate_role_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_role_stmt.
PostgreSQLParserListener.prototype.exitCreate_role_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_rule_event.
PostgreSQLParserListener.prototype.enterCreate_rule_event = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_rule_event.
PostgreSQLParserListener.prototype.exitCreate_rule_event = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_rule_stmt.
PostgreSQLParserListener.prototype.enterCreate_rule_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_rule_stmt.
PostgreSQLParserListener.prototype.exitCreate_rule_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_schema_stmt.
PostgreSQLParserListener.prototype.enterCreate_schema_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_schema_stmt.
PostgreSQLParserListener.prototype.exitCreate_schema_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_sequence_stmt.
PostgreSQLParserListener.prototype.enterCreate_sequence_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_sequence_stmt.
PostgreSQLParserListener.prototype.exitCreate_sequence_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_server_stmt.
PostgreSQLParserListener.prototype.enterCreate_server_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_server_stmt.
PostgreSQLParserListener.prototype.exitCreate_server_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_statistics_stmt.
PostgreSQLParserListener.prototype.enterCreate_statistics_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_statistics_stmt.
PostgreSQLParserListener.prototype.exitCreate_statistics_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_subscription_stmt.
PostgreSQLParserListener.prototype.enterCreate_subscription_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_subscription_stmt.
PostgreSQLParserListener.prototype.exitCreate_subscription_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_table_stmt.
PostgreSQLParserListener.prototype.enterCreate_table_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_table_stmt.
PostgreSQLParserListener.prototype.exitCreate_table_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_table_as_stmt.
PostgreSQLParserListener.prototype.enterCreate_table_as_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_table_as_stmt.
PostgreSQLParserListener.prototype.exitCreate_table_as_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_tablespace_stmt.
PostgreSQLParserListener.prototype.enterCreate_tablespace_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_tablespace_stmt.
PostgreSQLParserListener.prototype.exitCreate_tablespace_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_text_search_config_stmt.
PostgreSQLParserListener.prototype.enterCreate_text_search_config_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_text_search_config_stmt.
PostgreSQLParserListener.prototype.exitCreate_text_search_config_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_text_search_dict_stmt.
PostgreSQLParserListener.prototype.enterCreate_text_search_dict_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_text_search_dict_stmt.
PostgreSQLParserListener.prototype.exitCreate_text_search_dict_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_text_search_parser_stmt.
PostgreSQLParserListener.prototype.enterCreate_text_search_parser_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_text_search_parser_stmt.
PostgreSQLParserListener.prototype.exitCreate_text_search_parser_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_text_search_template_stmt.
PostgreSQLParserListener.prototype.enterCreate_text_search_template_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_text_search_template_stmt.
PostgreSQLParserListener.prototype.exitCreate_text_search_template_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_transform_stmt.
PostgreSQLParserListener.prototype.enterCreate_transform_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_transform_stmt.
PostgreSQLParserListener.prototype.exitCreate_transform_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_trigger_stmt.
PostgreSQLParserListener.prototype.enterCreate_trigger_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_trigger_stmt.
PostgreSQLParserListener.prototype.exitCreate_trigger_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_type_stmt.
PostgreSQLParserListener.prototype.enterCreate_type_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_type_stmt.
PostgreSQLParserListener.prototype.exitCreate_type_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_user_stmt.
PostgreSQLParserListener.prototype.enterCreate_user_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_user_stmt.
PostgreSQLParserListener.prototype.exitCreate_user_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_user_mapping_stmt.
PostgreSQLParserListener.prototype.enterCreate_user_mapping_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_user_mapping_stmt.
PostgreSQLParserListener.prototype.exitCreate_user_mapping_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#create_view_stmt.
PostgreSQLParserListener.prototype.enterCreate_view_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#create_view_stmt.
PostgreSQLParserListener.prototype.exitCreate_view_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#deallocate_stmt.
PostgreSQLParserListener.prototype.enterDeallocate_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#deallocate_stmt.
PostgreSQLParserListener.prototype.exitDeallocate_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#declare_stmt.
PostgreSQLParserListener.prototype.enterDeclare_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#declare_stmt.
PostgreSQLParserListener.prototype.exitDeclare_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#delete_stmt.
PostgreSQLParserListener.prototype.enterDelete_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#delete_stmt.
PostgreSQLParserListener.prototype.exitDelete_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#discard_stmt.
PostgreSQLParserListener.prototype.enterDiscard_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#discard_stmt.
PostgreSQLParserListener.prototype.exitDiscard_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_stmt.
PostgreSQLParserListener.prototype.enterDrop_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_stmt.
PostgreSQLParserListener.prototype.exitDrop_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_access_method_stmt.
PostgreSQLParserListener.prototype.enterDrop_access_method_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_access_method_stmt.
PostgreSQLParserListener.prototype.exitDrop_access_method_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_aggregate_stmt.
PostgreSQLParserListener.prototype.enterDrop_aggregate_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_aggregate_stmt.
PostgreSQLParserListener.prototype.exitDrop_aggregate_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_cast_stmt.
PostgreSQLParserListener.prototype.enterDrop_cast_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_cast_stmt.
PostgreSQLParserListener.prototype.exitDrop_cast_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_collation_stmt.
PostgreSQLParserListener.prototype.enterDrop_collation_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_collation_stmt.
PostgreSQLParserListener.prototype.exitDrop_collation_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_conversion_stmt.
PostgreSQLParserListener.prototype.enterDrop_conversion_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_conversion_stmt.
PostgreSQLParserListener.prototype.exitDrop_conversion_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_database_stmt.
PostgreSQLParserListener.prototype.enterDrop_database_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_database_stmt.
PostgreSQLParserListener.prototype.exitDrop_database_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_domain_stmt.
PostgreSQLParserListener.prototype.enterDrop_domain_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_domain_stmt.
PostgreSQLParserListener.prototype.exitDrop_domain_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_event_trigger_stmt.
PostgreSQLParserListener.prototype.enterDrop_event_trigger_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_event_trigger_stmt.
PostgreSQLParserListener.prototype.exitDrop_event_trigger_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_extension_stmt.
PostgreSQLParserListener.prototype.enterDrop_extension_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_extension_stmt.
PostgreSQLParserListener.prototype.exitDrop_extension_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_foreign_data_wrapper_stmt.
PostgreSQLParserListener.prototype.enterDrop_foreign_data_wrapper_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_foreign_data_wrapper_stmt.
PostgreSQLParserListener.prototype.exitDrop_foreign_data_wrapper_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_foreign_table_stmt.
PostgreSQLParserListener.prototype.enterDrop_foreign_table_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_foreign_table_stmt.
PostgreSQLParserListener.prototype.exitDrop_foreign_table_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_function_stmt.
PostgreSQLParserListener.prototype.enterDrop_function_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_function_stmt.
PostgreSQLParserListener.prototype.exitDrop_function_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_group_stmt.
PostgreSQLParserListener.prototype.enterDrop_group_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_group_stmt.
PostgreSQLParserListener.prototype.exitDrop_group_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_index_stmt.
PostgreSQLParserListener.prototype.enterDrop_index_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_index_stmt.
PostgreSQLParserListener.prototype.exitDrop_index_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_language_stmt.
PostgreSQLParserListener.prototype.enterDrop_language_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_language_stmt.
PostgreSQLParserListener.prototype.exitDrop_language_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_materialized_view_stmt.
PostgreSQLParserListener.prototype.enterDrop_materialized_view_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_materialized_view_stmt.
PostgreSQLParserListener.prototype.exitDrop_materialized_view_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_operator_stmt.
PostgreSQLParserListener.prototype.enterDrop_operator_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_operator_stmt.
PostgreSQLParserListener.prototype.exitDrop_operator_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_operator_class_stmt.
PostgreSQLParserListener.prototype.enterDrop_operator_class_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_operator_class_stmt.
PostgreSQLParserListener.prototype.exitDrop_operator_class_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_operator_family_stmt.
PostgreSQLParserListener.prototype.enterDrop_operator_family_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_operator_family_stmt.
PostgreSQLParserListener.prototype.exitDrop_operator_family_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_owned_stmt.
PostgreSQLParserListener.prototype.enterDrop_owned_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_owned_stmt.
PostgreSQLParserListener.prototype.exitDrop_owned_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_policy_stmt.
PostgreSQLParserListener.prototype.enterDrop_policy_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_policy_stmt.
PostgreSQLParserListener.prototype.exitDrop_policy_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_publication_stmt.
PostgreSQLParserListener.prototype.enterDrop_publication_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_publication_stmt.
PostgreSQLParserListener.prototype.exitDrop_publication_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_role_stmt.
PostgreSQLParserListener.prototype.enterDrop_role_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_role_stmt.
PostgreSQLParserListener.prototype.exitDrop_role_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_rule_stmt.
PostgreSQLParserListener.prototype.enterDrop_rule_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_rule_stmt.
PostgreSQLParserListener.prototype.exitDrop_rule_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_schema_stmt.
PostgreSQLParserListener.prototype.enterDrop_schema_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_schema_stmt.
PostgreSQLParserListener.prototype.exitDrop_schema_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_sequence_stmt.
PostgreSQLParserListener.prototype.enterDrop_sequence_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_sequence_stmt.
PostgreSQLParserListener.prototype.exitDrop_sequence_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_server_stmt.
PostgreSQLParserListener.prototype.enterDrop_server_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_server_stmt.
PostgreSQLParserListener.prototype.exitDrop_server_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_statistics_stmt.
PostgreSQLParserListener.prototype.enterDrop_statistics_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_statistics_stmt.
PostgreSQLParserListener.prototype.exitDrop_statistics_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_subscription_stmt.
PostgreSQLParserListener.prototype.enterDrop_subscription_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_subscription_stmt.
PostgreSQLParserListener.prototype.exitDrop_subscription_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_table_stmt.
PostgreSQLParserListener.prototype.enterDrop_table_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_table_stmt.
PostgreSQLParserListener.prototype.exitDrop_table_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_tablespace_stmt.
PostgreSQLParserListener.prototype.enterDrop_tablespace_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_tablespace_stmt.
PostgreSQLParserListener.prototype.exitDrop_tablespace_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_text_search_config_stmt.
PostgreSQLParserListener.prototype.enterDrop_text_search_config_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_text_search_config_stmt.
PostgreSQLParserListener.prototype.exitDrop_text_search_config_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_text_search_dict_stmt.
PostgreSQLParserListener.prototype.enterDrop_text_search_dict_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_text_search_dict_stmt.
PostgreSQLParserListener.prototype.exitDrop_text_search_dict_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_text_search_parser_stmt.
PostgreSQLParserListener.prototype.enterDrop_text_search_parser_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_text_search_parser_stmt.
PostgreSQLParserListener.prototype.exitDrop_text_search_parser_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_text_search_template_stmt.
PostgreSQLParserListener.prototype.enterDrop_text_search_template_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_text_search_template_stmt.
PostgreSQLParserListener.prototype.exitDrop_text_search_template_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_transform_stmt.
PostgreSQLParserListener.prototype.enterDrop_transform_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_transform_stmt.
PostgreSQLParserListener.prototype.exitDrop_transform_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_trigger_stmt.
PostgreSQLParserListener.prototype.enterDrop_trigger_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_trigger_stmt.
PostgreSQLParserListener.prototype.exitDrop_trigger_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_type_stmt.
PostgreSQLParserListener.prototype.enterDrop_type_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_type_stmt.
PostgreSQLParserListener.prototype.exitDrop_type_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_user_stmt.
PostgreSQLParserListener.prototype.enterDrop_user_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_user_stmt.
PostgreSQLParserListener.prototype.exitDrop_user_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_user_mapping_stmt.
PostgreSQLParserListener.prototype.enterDrop_user_mapping_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_user_mapping_stmt.
PostgreSQLParserListener.prototype.exitDrop_user_mapping_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#drop_view_stmt.
PostgreSQLParserListener.prototype.enterDrop_view_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#drop_view_stmt.
PostgreSQLParserListener.prototype.exitDrop_view_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#execute_stmt.
PostgreSQLParserListener.prototype.enterExecute_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#execute_stmt.
PostgreSQLParserListener.prototype.exitExecute_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#explain_stmt.
PostgreSQLParserListener.prototype.enterExplain_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#explain_stmt.
PostgreSQLParserListener.prototype.exitExplain_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#fetch_stmt.
PostgreSQLParserListener.prototype.enterFetch_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#fetch_stmt.
PostgreSQLParserListener.prototype.exitFetch_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#grant_stmt.
PostgreSQLParserListener.prototype.enterGrant_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#grant_stmt.
PostgreSQLParserListener.prototype.exitGrant_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#import_foreign_schema_stmt.
PostgreSQLParserListener.prototype.enterImport_foreign_schema_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#import_foreign_schema_stmt.
PostgreSQLParserListener.prototype.exitImport_foreign_schema_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#insert_stmt.
PostgreSQLParserListener.prototype.enterInsert_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#insert_stmt.
PostgreSQLParserListener.prototype.exitInsert_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#listen_stmt.
PostgreSQLParserListener.prototype.enterListen_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#listen_stmt.
PostgreSQLParserListener.prototype.exitListen_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#load_stmt.
PostgreSQLParserListener.prototype.enterLoad_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#load_stmt.
PostgreSQLParserListener.prototype.exitLoad_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#lock_stmt.
PostgreSQLParserListener.prototype.enterLock_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#lock_stmt.
PostgreSQLParserListener.prototype.exitLock_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#move_stmt.
PostgreSQLParserListener.prototype.enterMove_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#move_stmt.
PostgreSQLParserListener.prototype.exitMove_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#notify_stmt.
PostgreSQLParserListener.prototype.enterNotify_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#notify_stmt.
PostgreSQLParserListener.prototype.exitNotify_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#prepare_stmt.
PostgreSQLParserListener.prototype.enterPrepare_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#prepare_stmt.
PostgreSQLParserListener.prototype.exitPrepare_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#prepare_transaction_stmt.
PostgreSQLParserListener.prototype.enterPrepare_transaction_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#prepare_transaction_stmt.
PostgreSQLParserListener.prototype.exitPrepare_transaction_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#reassign_owned_stmt.
PostgreSQLParserListener.prototype.enterReassign_owned_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#reassign_owned_stmt.
PostgreSQLParserListener.prototype.exitReassign_owned_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#refresh_materialized_view_stmt.
PostgreSQLParserListener.prototype.enterRefresh_materialized_view_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#refresh_materialized_view_stmt.
PostgreSQLParserListener.prototype.exitRefresh_materialized_view_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#reindex_stmt.
PostgreSQLParserListener.prototype.enterReindex_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#reindex_stmt.
PostgreSQLParserListener.prototype.exitReindex_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#release_savepoint_stmt.
PostgreSQLParserListener.prototype.enterRelease_savepoint_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#release_savepoint_stmt.
PostgreSQLParserListener.prototype.exitRelease_savepoint_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#reset_stmt.
PostgreSQLParserListener.prototype.enterReset_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#reset_stmt.
PostgreSQLParserListener.prototype.exitReset_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#revoke_stmt.
PostgreSQLParserListener.prototype.enterRevoke_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#revoke_stmt.
PostgreSQLParserListener.prototype.exitRevoke_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#rollback_stmt.
PostgreSQLParserListener.prototype.enterRollback_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#rollback_stmt.
PostgreSQLParserListener.prototype.exitRollback_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#rollback_prepared_stmt.
PostgreSQLParserListener.prototype.enterRollback_prepared_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#rollback_prepared_stmt.
PostgreSQLParserListener.prototype.exitRollback_prepared_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#rollback_to_savepoint_stmt.
PostgreSQLParserListener.prototype.enterRollback_to_savepoint_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#rollback_to_savepoint_stmt.
PostgreSQLParserListener.prototype.exitRollback_to_savepoint_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#savepoint_stmt.
PostgreSQLParserListener.prototype.enterSavepoint_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#savepoint_stmt.
PostgreSQLParserListener.prototype.exitSavepoint_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#security_label_stmt.
PostgreSQLParserListener.prototype.enterSecurity_label_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#security_label_stmt.
PostgreSQLParserListener.prototype.exitSecurity_label_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#select_stmt.
PostgreSQLParserListener.prototype.enterSelect_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#select_stmt.
PostgreSQLParserListener.prototype.exitSelect_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#select_into_stmt.
PostgreSQLParserListener.prototype.enterSelect_into_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#select_into_stmt.
PostgreSQLParserListener.prototype.exitSelect_into_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#with_clause.
PostgreSQLParserListener.prototype.enterWith_clause = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#with_clause.
PostgreSQLParserListener.prototype.exitWith_clause = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#with_expr.
PostgreSQLParserListener.prototype.enterWith_expr = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#with_expr.
PostgreSQLParserListener.prototype.exitWith_expr = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#set_stmt.
PostgreSQLParserListener.prototype.enterSet_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#set_stmt.
PostgreSQLParserListener.prototype.exitSet_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#set_constraints_stmt.
PostgreSQLParserListener.prototype.enterSet_constraints_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#set_constraints_stmt.
PostgreSQLParserListener.prototype.exitSet_constraints_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#set_role_stmt.
PostgreSQLParserListener.prototype.enterSet_role_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#set_role_stmt.
PostgreSQLParserListener.prototype.exitSet_role_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#set_session_authorization_stmt.
PostgreSQLParserListener.prototype.enterSet_session_authorization_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#set_session_authorization_stmt.
PostgreSQLParserListener.prototype.exitSet_session_authorization_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#transaction_mode.
PostgreSQLParserListener.prototype.enterTransaction_mode = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#transaction_mode.
PostgreSQLParserListener.prototype.exitTransaction_mode = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#transaction_mode_list.
PostgreSQLParserListener.prototype.enterTransaction_mode_list = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#transaction_mode_list.
PostgreSQLParserListener.prototype.exitTransaction_mode_list = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#set_transaction_stmt.
PostgreSQLParserListener.prototype.enterSet_transaction_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#set_transaction_stmt.
PostgreSQLParserListener.prototype.exitSet_transaction_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#show_stmt.
PostgreSQLParserListener.prototype.enterShow_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#show_stmt.
PostgreSQLParserListener.prototype.exitShow_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#truncate_stmt.
PostgreSQLParserListener.prototype.enterTruncate_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#truncate_stmt.
PostgreSQLParserListener.prototype.exitTruncate_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#unlisten_stmt.
PostgreSQLParserListener.prototype.enterUnlisten_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#unlisten_stmt.
PostgreSQLParserListener.prototype.exitUnlisten_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#update_stmt.
PostgreSQLParserListener.prototype.enterUpdate_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#update_stmt.
PostgreSQLParserListener.prototype.exitUpdate_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#vacuum_opt.
PostgreSQLParserListener.prototype.enterVacuum_opt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#vacuum_opt.
PostgreSQLParserListener.prototype.exitVacuum_opt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#vacuum_opt_list.
PostgreSQLParserListener.prototype.enterVacuum_opt_list = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#vacuum_opt_list.
PostgreSQLParserListener.prototype.exitVacuum_opt_list = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#vacuum_stmt.
PostgreSQLParserListener.prototype.enterVacuum_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#vacuum_stmt.
PostgreSQLParserListener.prototype.exitVacuum_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#values_stmt.
PostgreSQLParserListener.prototype.enterValues_stmt = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#values_stmt.
PostgreSQLParserListener.prototype.exitValues_stmt = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#selector_clause.
PostgreSQLParserListener.prototype.enterSelector_clause = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#selector_clause.
PostgreSQLParserListener.prototype.exitSelector_clause = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#from_clause.
PostgreSQLParserListener.prototype.enterFrom_clause = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#from_clause.
PostgreSQLParserListener.prototype.exitFrom_clause = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#where_clause.
PostgreSQLParserListener.prototype.enterWhere_clause = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#where_clause.
PostgreSQLParserListener.prototype.exitWhere_clause = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#group_by_clause.
PostgreSQLParserListener.prototype.enterGroup_by_clause = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#group_by_clause.
PostgreSQLParserListener.prototype.exitGroup_by_clause = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#grouping_elem.
PostgreSQLParserListener.prototype.enterGrouping_elem = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#grouping_elem.
PostgreSQLParserListener.prototype.exitGrouping_elem = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#grouping_elem_list.
PostgreSQLParserListener.prototype.enterGrouping_elem_list = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#grouping_elem_list.
PostgreSQLParserListener.prototype.exitGrouping_elem_list = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#having_clause.
PostgreSQLParserListener.prototype.enterHaving_clause = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#having_clause.
PostgreSQLParserListener.prototype.exitHaving_clause = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#column_list.
PostgreSQLParserListener.prototype.enterColumn_list = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#column_list.
PostgreSQLParserListener.prototype.exitColumn_list = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#explain_parameter.
PostgreSQLParserListener.prototype.enterExplain_parameter = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#explain_parameter.
PostgreSQLParserListener.prototype.exitExplain_parameter = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#frame.
PostgreSQLParserListener.prototype.enterFrame = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#frame.
PostgreSQLParserListener.prototype.exitFrame = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#frame_start.
PostgreSQLParserListener.prototype.enterFrame_start = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#frame_start.
PostgreSQLParserListener.prototype.exitFrame_start = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#frame_end.
PostgreSQLParserListener.prototype.enterFrame_end = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#frame_end.
PostgreSQLParserListener.prototype.exitFrame_end = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#frame_clause.
PostgreSQLParserListener.prototype.enterFrame_clause = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#frame_clause.
PostgreSQLParserListener.prototype.exitFrame_clause = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#window_definition.
PostgreSQLParserListener.prototype.enterWindow_definition = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#window_definition.
PostgreSQLParserListener.prototype.exitWindow_definition = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#window_clause.
PostgreSQLParserListener.prototype.enterWindow_clause = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#window_clause.
PostgreSQLParserListener.prototype.exitWindow_clause = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#combine_clause.
PostgreSQLParserListener.prototype.enterCombine_clause = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#combine_clause.
PostgreSQLParserListener.prototype.exitCombine_clause = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#order_by_clause.
PostgreSQLParserListener.prototype.enterOrder_by_clause = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#order_by_clause.
PostgreSQLParserListener.prototype.exitOrder_by_clause = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#order_by_item.
PostgreSQLParserListener.prototype.enterOrder_by_item = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#order_by_item.
PostgreSQLParserListener.prototype.exitOrder_by_item = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#limit_clause.
PostgreSQLParserListener.prototype.enterLimit_clause = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#limit_clause.
PostgreSQLParserListener.prototype.exitLimit_clause = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#offset_clause.
PostgreSQLParserListener.prototype.enterOffset_clause = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#offset_clause.
PostgreSQLParserListener.prototype.exitOffset_clause = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#fetch_clause.
PostgreSQLParserListener.prototype.enterFetch_clause = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#fetch_clause.
PostgreSQLParserListener.prototype.exitFetch_clause = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#for_clause.
PostgreSQLParserListener.prototype.enterFor_clause = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#for_clause.
PostgreSQLParserListener.prototype.exitFor_clause = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#updater_clause.
PostgreSQLParserListener.prototype.enterUpdater_clause = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#updater_clause.
PostgreSQLParserListener.prototype.exitUpdater_clause = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#updater_expr.
PostgreSQLParserListener.prototype.enterUpdater_expr = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#updater_expr.
PostgreSQLParserListener.prototype.exitUpdater_expr = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#returning_clause.
PostgreSQLParserListener.prototype.enterReturning_clause = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#returning_clause.
PostgreSQLParserListener.prototype.exitReturning_clause = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#expr.
PostgreSQLParserListener.prototype.enterExpr = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#expr.
PostgreSQLParserListener.prototype.exitExpr = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#bool_expr.
PostgreSQLParserListener.prototype.enterBool_expr = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#bool_expr.
PostgreSQLParserListener.prototype.exitBool_expr = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#case_expr.
PostgreSQLParserListener.prototype.enterCase_expr = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#case_expr.
PostgreSQLParserListener.prototype.exitCase_expr = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#expr_list.
PostgreSQLParserListener.prototype.enterExpr_list = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#expr_list.
PostgreSQLParserListener.prototype.exitExpr_list = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#expr_list_list.
PostgreSQLParserListener.prototype.enterExpr_list_list = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#expr_list_list.
PostgreSQLParserListener.prototype.exitExpr_list_list = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#func_sig_arg.
PostgreSQLParserListener.prototype.enterFunc_sig_arg = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#func_sig_arg.
PostgreSQLParserListener.prototype.exitFunc_sig_arg = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#func_sig_arg_list.
PostgreSQLParserListener.prototype.enterFunc_sig_arg_list = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#func_sig_arg_list.
PostgreSQLParserListener.prototype.exitFunc_sig_arg_list = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#func_sig.
PostgreSQLParserListener.prototype.enterFunc_sig = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#func_sig.
PostgreSQLParserListener.prototype.exitFunc_sig = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#func_sig_list.
PostgreSQLParserListener.prototype.enterFunc_sig_list = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#func_sig_list.
PostgreSQLParserListener.prototype.exitFunc_sig_list = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#type_name.
PostgreSQLParserListener.prototype.enterType_name = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#type_name.
PostgreSQLParserListener.prototype.exitType_name = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#timezone.
PostgreSQLParserListener.prototype.enterTimezone = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#timezone.
PostgreSQLParserListener.prototype.exitTimezone = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#oper.
PostgreSQLParserListener.prototype.enterOper = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#oper.
PostgreSQLParserListener.prototype.exitOper = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#aggregate.
PostgreSQLParserListener.prototype.enterAggregate = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#aggregate.
PostgreSQLParserListener.prototype.exitAggregate = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#name_.
PostgreSQLParserListener.prototype.enterName_ = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#name_.
PostgreSQLParserListener.prototype.exitName_ = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#name_list.
PostgreSQLParserListener.prototype.enterName_list = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#name_list.
PostgreSQLParserListener.prototype.exitName_list = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#identifier_list.
PostgreSQLParserListener.prototype.enterIdentifier_list = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#identifier_list.
PostgreSQLParserListener.prototype.exitIdentifier_list = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#option_expr.
PostgreSQLParserListener.prototype.enterOption_expr = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#option_expr.
PostgreSQLParserListener.prototype.exitOption_expr = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#option_list.
PostgreSQLParserListener.prototype.enterOption_list = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#option_list.
PostgreSQLParserListener.prototype.exitOption_list = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#table_name_.
PostgreSQLParserListener.prototype.enterTable_name_ = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#table_name_.
PostgreSQLParserListener.prototype.exitTable_name_ = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#data_type.
PostgreSQLParserListener.prototype.enterData_type = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#data_type.
PostgreSQLParserListener.prototype.exitData_type = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#data_type_list.
PostgreSQLParserListener.prototype.enterData_type_list = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#data_type_list.
PostgreSQLParserListener.prototype.exitData_type_list = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#index_method.
PostgreSQLParserListener.prototype.enterIndex_method = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#index_method.
PostgreSQLParserListener.prototype.exitIndex_method = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#func_name.
PostgreSQLParserListener.prototype.enterFunc_name = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#func_name.
PostgreSQLParserListener.prototype.exitFunc_name = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#func_call.
PostgreSQLParserListener.prototype.enterFunc_call = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#func_call.
PostgreSQLParserListener.prototype.exitFunc_call = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#array_cons_expr.
PostgreSQLParserListener.prototype.enterArray_cons_expr = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#array_cons_expr.
PostgreSQLParserListener.prototype.exitArray_cons_expr = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#from_item.
PostgreSQLParserListener.prototype.enterFrom_item = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#from_item.
PostgreSQLParserListener.prototype.exitFrom_item = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#with_column_alias.
PostgreSQLParserListener.prototype.enterWith_column_alias = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#with_column_alias.
PostgreSQLParserListener.prototype.exitWith_column_alias = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#join_type.
PostgreSQLParserListener.prototype.enterJoin_type = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#join_type.
PostgreSQLParserListener.prototype.exitJoin_type = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#join_clause.
PostgreSQLParserListener.prototype.enterJoin_clause = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#join_clause.
PostgreSQLParserListener.prototype.exitJoin_clause = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#predicate.
PostgreSQLParserListener.prototype.enterPredicate = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#predicate.
PostgreSQLParserListener.prototype.exitPredicate = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#aggregate_signature.
PostgreSQLParserListener.prototype.enterAggregate_signature = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#aggregate_signature.
PostgreSQLParserListener.prototype.exitAggregate_signature = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#column_constraint.
PostgreSQLParserListener.prototype.enterColumn_constraint = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#column_constraint.
PostgreSQLParserListener.prototype.exitColumn_constraint = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#column_constraints.
PostgreSQLParserListener.prototype.enterColumn_constraints = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#column_constraints.
PostgreSQLParserListener.prototype.exitColumn_constraints = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#index_parameters.
PostgreSQLParserListener.prototype.enterIndex_parameters = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#index_parameters.
PostgreSQLParserListener.prototype.exitIndex_parameters = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#exclude_element.
PostgreSQLParserListener.prototype.enterExclude_element = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#exclude_element.
PostgreSQLParserListener.prototype.exitExclude_element = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#table_constraint.
PostgreSQLParserListener.prototype.enterTable_constraint = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#table_constraint.
PostgreSQLParserListener.prototype.exitTable_constraint = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#role_name.
PostgreSQLParserListener.prototype.enterRole_name = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#role_name.
PostgreSQLParserListener.prototype.exitRole_name = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#role_name_list.
PostgreSQLParserListener.prototype.enterRole_name_list = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#role_name_list.
PostgreSQLParserListener.prototype.exitRole_name_list = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#param_value.
PostgreSQLParserListener.prototype.enterParam_value = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#param_value.
PostgreSQLParserListener.prototype.exitParam_value = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#non_reserved_keyword.
PostgreSQLParserListener.prototype.enterNon_reserved_keyword = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#non_reserved_keyword.
PostgreSQLParserListener.prototype.exitNon_reserved_keyword = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#identifier.
PostgreSQLParserListener.prototype.enterIdentifier = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#identifier.
PostgreSQLParserListener.prototype.exitIdentifier = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#todo_fill_in.
PostgreSQLParserListener.prototype.enterTodo_fill_in = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#todo_fill_in.
PostgreSQLParserListener.prototype.exitTodo_fill_in = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#todo_implement.
PostgreSQLParserListener.prototype.enterTodo_implement = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#todo_implement.
PostgreSQLParserListener.prototype.exitTodo_implement = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#correlation_name.
PostgreSQLParserListener.prototype.enterCorrelation_name = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#correlation_name.
PostgreSQLParserListener.prototype.exitCorrelation_name = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#column_name.
PostgreSQLParserListener.prototype.enterColumn_name = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#column_name.
PostgreSQLParserListener.prototype.exitColumn_name = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#alias.
PostgreSQLParserListener.prototype.enterAlias = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#alias.
PostgreSQLParserListener.prototype.exitAlias = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#column_alias.
PostgreSQLParserListener.prototype.enterColumn_alias = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#column_alias.
PostgreSQLParserListener.prototype.exitColumn_alias = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#column_definition.
PostgreSQLParserListener.prototype.enterColumn_definition = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#column_definition.
PostgreSQLParserListener.prototype.exitColumn_definition = function(ctx) {
};


// Enter a parse tree produced by PostgreSQLParser#window_name.
PostgreSQLParserListener.prototype.enterWindow_name = function(ctx) {
};

// Exit a parse tree produced by PostgreSQLParser#window_name.
PostgreSQLParserListener.prototype.exitWindow_name = function(ctx) {
};



exports.PostgreSQLParserListener = PostgreSQLParserListener;
