import React from 'react';
import {Grid, IconButton, InputAdornment, Tooltip} from "@mui/material";
import TextField from "@mui/material/TextField";
import {ExternalConnection} from "../../../classes/model/ExternalConnection";
import PropTypes from "prop-types";
import {HelpOutline} from "@mui/icons-material";

const TimeSeriesForm = ({
                            newOutput,
                            setNewOutput,
                            forInput
                        }: { newOutput: ExternalConnection, setNewOutput: (x: ExternalConnection) => void, forInput: boolean }) => {

    const sequenceIndex = 'sequenceIndex';
    const entityColumns = 'entityColumns';

    function showMessage(type: string) {
        switch (type) {
            case sequenceIndex: {
                return "This is the name of the column that indicates the temporal sequence of the records";
            }
            case entityColumns: {
                //TODO: è pensato per funzionare con una stringa i cui entity sono separati tra loro da virgola spazio
                return "This is a comma seperated list of columns that indicates a single entity in your dataset. Can be empty.";
            }
        }
    }

    return (
        <>
            <Grid item xs={6}>
                <TextField
                    margin="dense"
                    id={sequenceIndex}
                    label="Sequence Index"
                    value={newOutput.sequenceIndex}
                    onChange={(i) => setNewOutput({...newOutput, sequenceIndex: i.target.value})}
                    type="text"
                    fullWidth
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position={"end"}>
                                <Tooltip title={showMessage(sequenceIndex)}>
                                    <IconButton
                                        aria-label="show-hint-sequence-index"
                                        onClick={() => {
                                        }}
                                    >
                                        <HelpOutline/>
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                        )
                    }}/>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    margin="dense"
                    id={entityColumns}
                    label="Entity Columns"
                    value={newOutput.entityColumns}
                    type="text"
                    onChange={(i) => setNewOutput({...newOutput, entityColumns: i.target.value})}
                    fullWidth
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position={"end"}>
                                <Tooltip title={showMessage(entityColumns)}>
                                    <IconButton
                                        aria-label="show-hint-entity-columns"
                                        onClick={() => {
                                        }}
                                    >
                                        <HelpOutline/>
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                        )
                    }}/>
            </Grid>
        </>
    );
};

TimeSeriesForm.propTypes = {
    newOutput: PropTypes.object
};

export default TimeSeriesForm;
