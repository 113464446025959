import * as React from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import ViewListIcon from '@mui/icons-material/ViewList';
import {useState} from "react";

const Input = styled(MuiInput)`
  width: 100%;
`;

export default function SliderWithNumber({number, setNumber, max = 0}) {

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setNumber(newValue);
    };

    const [virtual, setVirtual] = useState(number);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNumber(event.target.value === '' ? '' : Number(event.target.value));
    };

    const handleBlur = () => {
        if (number < 0) {
            setNumber(0);
        } else if (number > max) {
            setNumber(max);
        }
    };

    return (
        <Box sx={{ width: 450 }}>
            <Typography align={"center"} id="input-slider" gutterBottom>
                Number of generated rows
            </Typography>
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <ViewListIcon />
                </Grid>
                <Grid item xs>
                    <Slider
                        value={typeof number === 'number' ? number : 0}
                        step={10}
                        max={max}
                        onChange={handleSliderChange}
                        // onChangeCommitted={handleSliderChange}
                        aria-labelledby="input-slider"
                    />
                </Grid>
                <Grid item xs={2}>
                    <Input
                        value={number}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        inputProps={{
                            step: 10,
                            min: 0,
                            max: max,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
