import React from 'react';
import {Typography} from "@mui/material";

const Unauthorized = () => {
    return (
        <div>
            <Typography variant="h4" component="h2">
                Unauth
            </Typography>
        </div>
    );
};

export default Unauthorized;
