import axios from "axios";
import Cookies from "js-cookie";

const instance = axios.create({
    proxy: {
        host: "localhost",
        protocol: "http",
        port: 8433
    },
    headers: {
        "Content-Type": "application/json",
    },
});

const BASE_URL = process.env.NODE_ENV == "production" ? "/auth-framework-0.0.1/" : "";

instance.interceptors.request.use(
    (config) => {
        config.withCredentials = true;
        const token = Cookies.get("ACT");
        if (token) {
            config.headers["ACT"] = token;
            config.headers["RFT"] = Cookies.get("RFT");
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// instance.interceptors.response.use(
//     (res) => {
//         return res;
//     },
//     async (err) => {
//         const originalConfig = err.config;
//
//         if (originalConfig.url !== BASE_URL+"/public/login" && err.response) {
//             // Access Token was expired
//             if (err.response.status === 401 && !originalConfig._retry) {
//                 originalConfig._retry = true;
//                 try {
//                     await instance.get(BASE_URL+"/public/refresh-token", {withCredentials: true});
//                     return instance(originalConfig);
//                 } catch (_error) {
//                     Cookies.remove("ACT");
//                     Cookies.remove("RFT");
//                     window.location.href = "/";
//                     return Promise.reject(_error);
//                 }
//             }
//         }
//         return Promise.reject(err);
//     }
// );


let refreshTokenPromise // this holds any in-progress token refresh requests

// I just moved this logic into its own function
const getRefreshToken = () => instance.get(BASE_URL + "/public/refresh-token", {withCredentials: true}).then(() => {
})

instance.interceptors.response.use(r => r, error => {
    if (error.config && error.response && error.response.status === 401) {
        if (!refreshTokenPromise) { // check for an existing in-progress request
            refreshTokenPromise = getRefreshToken().then(() => { // if nothing is in-progress, start a new refresh token request
                refreshTokenPromise = null // clear state
                return Cookies.get("ACT") // resolve with the new token
            })
        }
        return refreshTokenPromise.then(() => {
            const token = Cookies.get("ACT");
            if (token) {
                error.config.headers["ACT"] = token;
                error.config.headers["RFT"] = Cookies.get("RFT");
            }
            return instance(error.config);
        }).catch(() => {
            Cookies.remove("ACT");
            Cookies.remove("RFT");
            window.location.href = "/";
        })
    }

    return Promise.reject(error)
})

export default instance;
