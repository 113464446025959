import React, {useRef} from 'react';
import {Button, Grid, Link} from "@mui/material";

const ZipForm = ({newOutput, setNewOutput, forInput, isEditing = false}) => {

    const ref = useRef(null);

    return (
        <>
            {(!ref.current?.files[0]) &&
            <Grid item xs={6}>
                <a href={"/public/multitable_example.zip"} download="example.zip"
                   style={{marginTop: "0.5rem", textDecoration: "none"}}>
                    <Button variant={"contained"} component="span" style={{marginTop: "1rem"}}>
                        {ref.current?.files[0] ? ref.current?.files[0]?.name : "DOWNLOAD EXAMPLE FILE"}
                    </Button>
                </a>
            </Grid>}

            <Grid item xs={6}>
                <input
                    ref={ref}
                    style={{display: 'none'}}
                    id="raised-button-file-upload"
                    type="file"
                    accept=".zip"
                    onChange={(i) => {
                        let t = i.target as HTMLInputElement;
                        if (t != null && t.files != null && t.files[0] != null) {
                            setNewOutput({...newOutput, content: (t.files[0] || 0)})
                        }
                    }}
                />
                <label htmlFor="raised-button-file-upload"
                       style={{display: "block"}}>
                    <Button variant={"contained"} component="span" style={{marginTop: "1rem"}}>
                        {ref.current?.files[0] ? ref.current?.files[0]?.name : "UPLOAD .ZIP FILE"}
                    </Button>
                </label>
            </Grid>
            {isEditing &&
                <Grid item xs={12}>
                    <span><br/>Original file is mantained if you don't upload a new one</span>
                </Grid>
            }
        </>
    );
};

export default ZipForm;
