import React, {useEffect, useState} from 'react';
import {DataGrid, GridRenderCellParams, GridToolbarContainer} from "@mui/x-data-grid";
import {ExternalConnection} from "../../classes/model/ExternalConnection";
import {BackEndUtils} from "../../utils/BackEndUtils";
import {Grid, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import DialogExternalConnection from "../dialogs/DialogExternalConnection";
import {GridRTL} from "../generic/GenericGridRTL";
import {Constants} from "../../utils/Constants";
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import GenericIconAction from "../generic/GenericIconAction";

const CustomToolbar = ({refreshInputs, openNewInput, specific, openTempo}) => {
    return (
        <GridToolbarContainer>
            <Grid container alignItems={"center"}>
                <Grid item xs={4}>
                    <Typography variant="h4" component="h2" style={{padding: ".7rem"}}>
                        Inputs List
                    </Typography>
                </Grid>
                <GridRTL item xs={8} dir={"rtl"}>
                    {specific && <Button onClick={() => openNewInput()} variant={"contained"}>Add input</Button>}
                    {!specific &&
                    <Button onClick={() => openTempo()} variant={"outlined"}>Add temporary input</Button>}
                    <Button onClick={() => refreshInputs()} variant={"outlined"}>Refresh inputs</Button>
                </GridRTL>
            </Grid>
        </GridToolbarContainer>
    )
}


const InputList = ({
                       specific = true, setInputFromList = (x) => {
    }, user
                   }) => {

    const columns = [
        {field: 'connectionIdentifier', headerName: 'ID', flex: 1},
        {field: 'connectionName', headerName: 'Input name', flex: 0.4},
        {field: 'modelType', headerName: 'ModelType', flex: 0.4},
        {field: 'type', headerName: "Type", flex: 0.2},
        {field: 'description', headerName: "Description", flex: 0.3},
        {
            field: 'done', headerName: "", width: 100, renderCell: (params: GridRenderCellParams) => {
                return (
                    <Grid container alignItems={"center"} justifyContent={"center"}>
                        <GridRTL item xs={8} dir={"rtl"}>
                            <GenericIconAction text={"Edit Input"} icon={<BuildCircleIcon fontSize="large" color={"primary"}/>}
                                               tooltipDirection={Constants.TOOLTIP.PLACEMENTS.TOP}
                                               disableTooltip={false} color={"primary"} action={openEditInputFunc}/>
                        </GridRTL>
                    </Grid>
                )
            }
        }
    ];

    const addTempoRow = (tempo) => {
        setRows([...rows, {...tempo, id: 99999}]);
    }

    const [selectionModel, setSelectionModel] = useState<any[]>([]);
    const [rows, setRows] = useState<ExternalConnection[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [openNewInput, setOpenNewInput] = useState<boolean>(false);
    const [openTempo, setOpenTempo] = useState<boolean>(false);
    const [openEditInput, setOpenEditInput] = useState<boolean>(false);
    const [selectedInput, setSelectedInput] = useState<ExternalConnection>(new ExternalConnection());
    const be = BackEndUtils.getInstance();

    function refreshInputs() {
        if (user) {
            setLoading(true);
            be.sendFilter("/external-connection/filter", {
                "input": true,
                owner: user.email
            }).then(l => {
                const m = l.map((e: ExternalConnection) => {
                    if (e.extraConnectionString)
                        e.extraConnectionString = window.atob(e.extraConnectionString);
                    return Object.assign(new ExternalConnection(), e);
                })
                setRows(m);
                setLoading(false);
            });
        }
    }

    const openEditInputFunc = () => {
        setOpenEditInput(true);
    }

    useEffect(() => {
        refreshInputs();
    }, [user]);

    useEffect(() => {
        for (let x of rows)
            if (selectionModel[0] === x?.id) {
                setSelectedInput(x);
                setInputFromList(x);
            }
    }, [rows, selectionModel, setInputFromList]);


    return (
        <>
            <DataGrid columns={columns} rows={rows}
                      components={{Toolbar: CustomToolbar}}
                      componentsProps={{
                          toolbar: {
                              refreshInputs: refreshInputs,
                              openNewInput: () => setOpenNewInput(true),
                              openEditInput: openEditInputFunc,
                              isSomethingSelected: selectionModel[0],
                              specific: specific,
                              openTempo: () => setOpenTempo(true),
                          }
                      }}
                      loading={loading}
                      onSelectionModelChange={(newSelectionModel) => {
                          setSelectionModel(newSelectionModel);
                          for (let x of rows)
                              if (newSelectionModel[0] === x?.id) {
                                  setSelectedInput(x as ExternalConnection);
                                  setInputFromList(x as ExternalConnection);
                              }
                      }}
                      selectionModel={selectionModel}
            />
            <DialogExternalConnection forInput={true} openDialog={openNewInput}
                                      setOpenDialog={setOpenNewInput}
                                      isEditing={false}
                                      externalConnection={new ExternalConnection()}
                                      refreshInputs={refreshInputs}
                                      user={user}

            />
            <DialogExternalConnection forInput={true} openDialog={openTempo}
                                      setOpenDialog={setOpenTempo}
                                      isEditing={false}
                                      externalConnection={new ExternalConnection()}
                                      isTempoForce={true}
                                      addTempoToList={addTempoRow}
                                      refreshInputs={refreshInputs}
                                      user={user}
            />
            <DialogExternalConnection forInput={true} openDialog={openEditInput}
                                      setOpenDialog={setOpenEditInput}
                                      isEditing={true}
                                      externalConnection={selectedInput}
                                      user={user}
                                      refreshInputs={refreshInputs}/>
        </>
    );
};

export default InputList;
