import {Constants} from "../../utils/Constants";

export class ExternalConnection {
    id?: number;
    host?: string;
    port?: number;
    input?: boolean;

    username?: string;
    password?: string;

    owner?: number;

    type?: string;

    database?: string;

    extraConnectionString?: string;

    tableName?: string;

    topicName?: string;
    fileName?: string;
    content?: any;
    connectionName?: string;

    connectionIdentifier?: string;
    modelType?: string;

    description: string = "";

    pathToExternal: string;

    //FOR TIME SERIES MODEL
    sequenceIndex: string;
    entityColumns: string = "";


    isComplete = (obj, forInput: boolean) => {
        if (forInput && obj.modelType === Constants.MODEL_TYPE.MULTI_TABLE) {
            return obj.connectionName && obj.content;
        }
        if (obj.connectionName && obj.connectionName.length < 1)
            return false;

        if (obj.type && obj.type.length < 1)
            return false;

        if (obj.type === Constants.EXTERNAL_TYPE.FILE || obj.type === Constants.EXTERNAL_TYPE.TEMPO) {
            return obj.connectionName && ((obj.content && forInput) || !forInput);
        }

        if (obj.type === Constants.EXTERNAL_TYPE.KAFKA) {
            return obj.host && obj.port && (obj.modelType !== Constants.MODEL_TYPE.MULTI_TABLE && obj.topicName);
        }

        if (obj.type === Constants.EXTERNAL_TYPE.MQTT)
            return obj.host && obj.port && (obj.modelType === Constants.MODEL_TYPE.MULTI_TABLE ||  obj.topicName) && obj.username && obj.password

        if (obj.type === Constants.EXTERNAL_TYPE.POSTGRES) {
            return obj.host && obj.port && obj.database && obj.username && obj.password && ((!forInput && obj.modelType == Constants.MODEL_TYPE.MULTI_TABLE) || obj.tableName || obj.extraConnectionString);
        }

        if (obj.modelType === "timeSeries") {
            return obj.sequenceIndex;
        }
        return false;
    }
}
