import React, {useRef} from 'react';
import {Button, Grid} from "@mui/material";
import TextField from "@mui/material/TextField";

const FileForm = ({newOutput, setNewOutput, forInput, isEditing = false}) => {

    const ref = useRef(null);

    return (
        <>
            {!forInput && <Grid item xs={6}>
                <TextField
                    margin="dense"
                    id="FileName"
                    label={"File Name"}
                    value={newOutput.fileName}
                    onChange={(i) => setNewOutput({...newOutput, fileName: i.target.value})}
                    type="text"
                    fullWidth
                    variant="standard"
                />
            </Grid>}
            {forInput && <Grid item xs={12}>
                <input
                    ref={ref}
                    style={{display: 'none'}}
                    id="raised-button-file"
                    type="file"
                    accept="text/csv, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={(i) => {
                        let t = i.target as HTMLInputElement;
                        if (t != null && t.files != null && t.files[0] != null) {
                            setNewOutput({...newOutput, content: (t.files[0] || 0)})
                        }
                    }}
                />
                <label htmlFor="raised-button-file"
                       style={{display: "block", margin: "0.5rem 0 0.5rem"}}>
                    {isEditing ? <span><br/>Original file is mantained if you don't upload a new one</span> : ""}
                    <Button variant={"contained"} component="span" style={{marginTop: "0.5rem"}}>
                        {ref.current?.files[0] ? ref.current?.files[0]?.name : "Upload a file"}
                    </Button>
                </label>
            </Grid>}
        </>
    );
};

export default FileForm;
