import React, {useContext, useEffect, useState} from 'react';
import {Button, Grid, TextField, Typography} from "@mui/material";
import WhiteLogo from "../components/customIcons/WhiteLogo";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {styled} from "@mui/material/styles";
import {AlertContext} from "../components/generic/AlertContext";
import {useNavigate} from "react-router-dom";
import {Constants} from "../utils/Constants";
import AuthService from "../utils/services/auth.service";
import ReCAPTCHA from "react-google-recaptcha";

const Input = styled(TextField)`
  color: white;
  border-color: white;
  height: 100%;

  div {
    background: rgb(127, 206, 251, 0.3);
  }

  & ::placeholder {
    color: white !important;
    opacity: 1 !important;
  }

  p {
    background: transparent !important;
  }
`;

const LandingPageV2 = ({setMode}) => {

        const {setContext} = useContext(AlertContext);
        const navigate = useNavigate();

        const [email, setEmail] = useState("");
        const [errorEmail, setErrorEmail] = useState(false);
        const [errorEmailText, setErrorEmailText] = useState(" ");


        function checkEmail(): boolean {
            const regex = Constants.emailRegex;
            if (email.match(regex) === null) {
                setErrorEmail(true);
                setErrorEmailText("Please insert a valid email")
                return false;
            } else {
                setErrorEmail(false);
                setErrorEmailText(" ");
                return true;
            }
        }

        const recaptchaRef = React.createRef<ReCAPTCHA>();

        const onSubmitWithReCAPTCHA = async () => {
            if (recaptchaRef.current) {
                const token = await recaptchaRef.current.executeAsync();
                if (token) {
                    fireUp()
                }
            }
            // apply to form data
        }


        useEffect(() => setMode('dark'), []);


        function fireUp(): void {
            if (checkEmail()) {
                AuthService.registerOnlyEmail({email}).then(
                    () => {
                        navigate("/dashboard", {replace: true});
                        if(window['lintrk'] != null) {
                            window['lintrk']('track', {conversion_id: 8618041});
                        }
                    },
                    error => {
                        setContext({
                            text: Constants.MESSAGES.ERROR_TITLE,
                            content: Constants.MESSAGES.REGISTRATION_ERROR_CONTENT,
                            severity: Constants.ALERT_SEVERITY.ERROR,
                            open: true
                        })
                    }
                );
            }
        }

        function goToLogin(): void {
            navigate("/login", {replace: true});
        }

        return (
            <Grid container xs={12} justifyContent={"start"}
                  sx={{
                      p: {
                          xxs: "8%",
                          xs: "8%",
                          sm: "6%",
                          md: "6%",
                          lg: "4%",
                          lgr: "4%", //questo è hp
                          xl: "4%",
                      },
                      height: "100vh"
                  }}
                  alignContent={"space-between"}>
                <Grid container item xs={8} sm={5} md={4} lg={2} justifyContent={"start"} alignSelf={"start"} alignItems={"start"}
                      sx={{cursor: "pointer"}}>
                    <WhiteLogo/>
                </Grid>

                <Grid xs={12} lg={9}/>
                <Grid xs={12} lg={8}>
                    <Grid container columns={12}
                          alignContent={"center"}
                          alignItems={"stretch"}
                          rowSpacing={3}
                          justifyContent={"flex-start"}
                          style={{
                              color: "white", position: "relative",
                              zIndex: "5"
                          }}>
                        <Grid item xs={12}>
                            <Typography sx={{
                                fontWeight: "700",
                                pb: "0",
                                lineHeight: {
                                    xs: "1.3"
                                },
                                fontSize: {
                                    xxs: "2rem",
                                    xs: "2.5rem",
                                    sm: "3.4rem",
                                    md: "4.2rem",
                                    lg: "4.2rem",
                                    lgr: "4.4rem",
                                    xl: "5.6rem",
                                }
                            }}>
                                FORGET <span style={{color: Constants.COLORS.PURPLE}}>GDPR</span> CONCERNS!
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={12} sx={{pt: "0"}}>
                            <Typography sx={{
                                fontWeight: "200", pt: "0",
                                fontSize: {
                                    xxs: "0.8rem",
                                    xs: "1rem",
                                    sm: "1.3rem",
                                    md: "1.3rem",
                                    lg: "1.3rem",
                                    lgr: "1.3rem",
                                    xl: "1.5rem",
                                }
                            }}>
                                DataGAN generates synthetic data that is indistinguishable from your real data, but still truly
                                different.
                                No privacy concerns, no scarcity problems, no hassle at all.</Typography>
                        </Grid>
                        <Grid container columns={12} alignContent={"center"} alignItems={"stretch"}
                              justifyContent={"flex-start"} sx={{pt: "2.0rem"}}
                              rowSpacing={{
                                  xxs: "2",
                                  xs: "0.2",
                                  sm: "0.2rem",
                                  md: "0.2",
                                  lg: "0.2",
                                  lgr: "0.2",
                                  xl: "0.2"
                              }}>
                            <Grid item container xs={12} md={6} justifyContent={"start"} sx={{
                                fontSize: {
                                    xxs: "0.8rem !important",
                                    xs: "1rem !important",
                                    sm: "1.3rem !important",
                                    md: "1.3rem !important",
                                    lg: "1.3rem !important",
                                    lgr: "1.3rem !important",
                                    xl: "1.5rem !important",
                                }
                            }}>
                                <Input
                                    placeholder="Start for free* with your e-mail"
                                    value={email}
                                    error={errorEmail}
                                    helperText={errorEmailText}
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                    }}
                                    sx={{
                                        width: {
                                            xxs: "100%",
                                            xs: "100%",
                                            sm: "100%",
                                            md: "95%",
                                            lg: "95%",
                                            lgr: "95%",
                                            xl: "95%",
                                        }
                                    }}
                                    inputProps={{
                                        style: {
                                            textAlign: "center",
                                            color: "white",
                                            opacity: "1",
                                            fontWeight: "200",
                                            position: "relative",
                                            zIndex: "5"
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item container xs={12} md={3} justifyContent={"center"}
                                  sx={{
                                      marginBottom: {
                                          xxs: "1.6rem",
                                          xs: "1.7rem",
                                          sm: "1.7rem",
                                          md: "0",
                                          lg: "0",
                                          lgr: "0",
                                          xl: "0",
                                      }
                                  }}>
                                <Button variant="contained" className={"fireUp"}
                                        sx={{
                                            textAlign: "center",
                                            paddingRight: "0",
                                            fontSize: "1rem",
                                            position: "relative",
                                            zIndex: "5",
                                            height: {
                                                xxs: "116%",
                                                xs: "116%",
                                                sm: "116%",
                                                md: "75%"
                                            },
                                            width: {
                                                xxs: "100%",
                                                xs: "100%",
                                                sm: "100%",
                                                md: "80%"
                                            }
                                        }} endIcon={
                                    <ArrowRightIcon className={"triangle"}  sx={{
                                        marginLeft: "-0.5rem",
                                        marginBottom: {
                                            xxs: "0.1rem",
                                            xs: "0.1rem",
                                            sm: "0"
                                        },
                                        fontSize: {
                                            xxs:"1rem",
                                            xs: "1rem",
                                            sm: "2rem !important",
                                            md: "2rem !important",
                                            lg: "2rem !important",
                                            lgr:"2rem !important",
                                            xl: "2rem !important"
                                        }
                                    }}/>}
                                        onClick={onSubmitWithReCAPTCHA}>FIRE UP</Button>
                            </Grid>
                            <Grid item container xs={12} md={3} justifyContent={"center"}>
                                <Button variant="outlined"
                                        sx={{
                                            textAlign: "center",
                                            fontSize: "1rem",
                                            position: "relative",
                                            zIndex: "5",
                                            height: {
                                                xxs: "116%",
                                                xs: "116%",
                                                sm: "116%",
                                                md: "75%"
                                            },
                                            width: {
                                                xxs: "100%",
                                                xs: "100%",
                                                sm: "100%",
                                                md: "80%"
                                            },
                                            border: "2px solid #00D3FF"
                                        }} onClick={goToLogin}>LOGIN</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid xs={12} lg={4}/>

                <Grid container item xs={12} justifyContent={"start"} sx={{
                    fontSize: {
                        xxs: "0.7rem",
                        xs: "0.8rem",
                        sm: "0.8rem",
                        md: "0.8rem",
                        lg: "0.8rem",
                        lgr: "0.8rem",
                        xl: "0.9rem",
                    },
                    lineHeight: {
                        xxs: "1.5",
                        xs: "1.5",
                        sm: "1.5",
                        md: "1.5",
                        lg: "1.5",
                        lgr: "1.5",
                        xl: "1.5"
                    }
                }}>
                    <ul style={{
                        color: "white",
                        fontWeight: "200",
                        fontSize: "1em",
                        listStyleType: "none",
                        padding: "0"
                    }}>
                        <li>* no credit card</li>
                        <li>* no need to provide data (use our demo datasets)</li>
                        <li>* no waste of time (few seconds per demo)</li>
                    </ul>
                </Grid>
                <img className={'sparks-image'} src={'sparks.png'} alt={''}/>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    theme="dark"
                    style={{position: "relative", zIndex: 5}}
                    sitekey={Constants.CAPTCHA_SITE_KEY}
                />
            </Grid>
        );
    }
;

export default LandingPageV2;
