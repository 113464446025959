import * as React from 'react';
import {IconButton, Tooltip} from "@mui/material";

export default function GenericIconAction({text, icon, tooltipDirection, disableTooltip, color, action = ()=>{}}) {
    return(
        <Tooltip enterDelay={500} title={text} placement={tooltipDirection} disableFocusListener={disableTooltip} disableTouchListener={disableTooltip} disableHoverListener={disableTooltip}>
            <IconButton color={color} onClick={action}>
                {icon}
            </IconButton>
        </Tooltip>
    );
}
