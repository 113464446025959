import {LoginRequest} from "../../classes/model/LoginRequest";
import api from "./api"
import decode, {JwtPayload} from "jwt-decode"
import Cookies from "js-cookie"

// https://datagan.iet.it:8443/auth-framework-0.0.1/public/login

const BASE_URL = process.env.NODE_ENV === "production" ? "/auth-framework-0.0.1/" : "/";
const API_URL = BASE_URL + "public/";
const publicToken = "QqfPlV9VdOzb6MZtji";

class AuthService {

    login(loginRequest: LoginRequest) {
        let email = loginRequest.email;
        let password = loginRequest.password;
        let rememberMe = loginRequest.rememberMe || false;
        return api
            .post(API_URL + "login", {
                email,
                password,
                rememberMe
            }, {withCredentials: true})
            .then(response => {
            });
    }

    resetPassword(email) {
        return api.post(API_URL + "reset-password", email)
    }

    updatePassword({token, password}) {
        return api.post(API_URL + "update-password", {
            token,
            password
        })
    }

    logout() {
        let arr = [Cookies.get("ACT"), Cookies.get("RFT")];
        return api.post(API_URL + "logout", {tokens: arr}, {
            withCredentials: true
        }).then((l) => {
            if (l) {
                Cookies.remove("ACT")
                Cookies.remove("RFT")
            }
            return Promise.resolve(l);
        }).catch((err)=>{
            return Promise.resolve(err);
        });
    }

    register({
                 firstName,
                 lastName,
                 email,
                 password
             }) {
        return api.post(API_URL + "signup", {
            firstName,
            lastName,
            email,
            password
        }, {
            withCredentials: true, headers: {
                "Sign-Token": publicToken
            }
        });
    }

    registerOnlyEmail({
                          email
                      }) {
        return api.post(API_URL + "signup-email", {
            email
        }, {
            withCredentials: true, headers: {
                "Sign-Token": publicToken
            }
        });
    }

    getCurrentToken(): JwtPayload {
        try {
            return decode<JwtPayload>(Cookies.get("ACT"))
        } catch (e) {
            return null;
        }
    }

    getCurrentUser() {
        let token = this.getCurrentToken();
        if (token)
            return api.get(BASE_URL + "user/" + token.sub).then(a => a)
        return Promise.resolve(null);
    }

    updateUser({
                   firstName,
                   lastName,
                   email,
               }) {
        return api.post(BASE_URL + "update-user", {
            firstName,
            lastName,
            email,
        }, {
            withCredentials: true
        });
    }


    verifyEmail(token: string | undefined) {
        if (!token){
            return Promise.reject(false)
        }
        return api.post(API_URL+"confirm-email?confirmToken="+token, {})
    }
}

export default new AuthService();
