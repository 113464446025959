import React from 'react';
import {Grid, Skeleton} from "@mui/material";

const TableSkeleton = () => {
    return (
        <Grid container item xs={12} alignItems={"flex-start"} sx={{p: 1}} rowGap={3}>
            <Grid item xs={11}>
                <Skeleton variant="text" width={"100%"} height={40} />
            </Grid>
            <Grid item xs={0.5} container justifyContent={"center"}>
            <Skeleton variant="circular" width={40} height={40} />

            </Grid>
            <Grid item xs={0.5} container justifyContent={"center"}>
            <Skeleton variant="circular" width={40} height={40} />

            </Grid>
            <Grid xs={12}>
                <Skeleton variant="rectangular" height={"calc(100vh - 120px)"}/>
            </Grid>
        </Grid>
    );
};

export default TableSkeleton;
