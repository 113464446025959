import React from 'react';
import {Outlet} from "react-router-dom";

const ProjectsHomepage = () => {
    return (
        <Outlet/>
    );
};

export default ProjectsHomepage;
