import React, {useEffect, useState} from 'react';
import AuthService from "../utils/services/auth.service";
import {useParams, useSearchParams} from "react-router-dom";
import {CircularProgress, Grid, Link, Typography} from "@mui/material";
import HeaderBar from "../components/HeaderBar";

const VerifyEmail = ({mode, setMode}) => {

    const [verified, setVerified] = useState(null);

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        AuthService.verifyEmail(searchParams.get("token")).then(l => {
            if (l.data == null)
                setVerified(false);
            else
                setVerified(true);
        }).catch(reason => {
            setVerified(false)
        })
    }, []);


    return (
        <>
        <HeaderBar mode={mode} setMode={setMode}/>
            <Typography>{verified}</Typography>
        <Grid container columns={12} justifyContent="center" alignContent="center" sx={{
            color: "white",
            width: "100%"
        }}>
            <Grid item container columns={12} xs={10} sm={12} justifyContent={"center"}>
                {verified == null &&
                    <>
                        <Typography>We are verifying your email</Typography>
                        <CircularProgress/>
                    </>
                }

                {verified == false &&
                    <Typography>We failed to verify your email</Typography>
                }

                {verified &&
                    <Typography>Congratulation, your email is verified, you can return to our <Link href={"/"}>homepage</Link></Typography>
                }
            </Grid>
        </Grid>
        </>
    );
};

export default VerifyEmail;
