import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";

import {CircularProgress, Grid, Stack, Typography,} from "@mui/material";
import {BackEndUtils} from "../../utils/BackEndUtils";
import OutputsList from "../../components/tables/OutputsList";
import {ExternalConnection} from "../../classes/model/ExternalConnection";
import InputList from "../../components/tables/InputList";
import Button from "@mui/material/Button";
import {Project} from "../../classes/model/Project";
import SliderWithNumber from "../../components/customInputs/SliderWithNumber";
import {Constants} from "../../utils/Constants";
import useUser from "../../utils/hooks/useUser";
import {AlertContext} from "../../components/generic/AlertContext";

const MainContentDashboard = () => {

    const be = BackEndUtils.getInstance();
    const params = useParams();
    const {setContext} = useContext(AlertContext);
    const [output, setOutput] = useState<ExternalConnection>();
    const [input, setInput] = useState<ExternalConnection>();
    const [number, setNumber] = useState(50);
    const [project, setProject] = useState<Project>();
    const [isGenerating, setIsGenerating] = useState(false);
    const [maximumNumber, setMaximumNumber] = useState(0);
    const {user} = useUser([]);

    const [isMulti, setIsMulti] = useState(false);

    const refreshProject = async () => {
        return await be.sendGetById("/project", Number(params.projectId));
    }

    const refreshIsMulti = async () => {
        let ext: ExternalConnection = (await be.sendGetById("/external-connection", project.input.id))
        setIsMulti(ext.modelType == Constants.MODEL_TYPE.MULTI_TABLE);
    }

    useEffect(() => {
        refreshProject().then(l => setProject(l));
        be.sendGet("/metadata/recordLimit").then(l => setMaximumNumber(Number(l.value)))
    }, []);

    useEffect(() => {
        if(project?.input)
            refreshIsMulti().then(r => r)
    }, [project?.input]);



    function openSuccessDialog(msg) {
        setContext({
            text: Constants.MESSAGES.SUCCESS_TITLE,
            content: msg,
            severity: Constants.ALERT_SEVERITY.SUCCESS,
            open: true
        })
        setIsGenerating(false);
    }

    function openErrorDialog(msg) {
        setContext({
            text: Constants.MESSAGES.ERROR_TITLE,
            content: msg,
            severity: Constants.ALERT_SEVERITY.ERROR,
            open: true
        })
        setIsGenerating(false);
    }

    function sendTrain() {
        if (input && input.id && input.type !== "temporary") {
            setIsGenerating(true);

            setContext({
                text: Constants.MESSAGES.SUCCESS_TITLE,
                content: "Train request sent",
                severity: Constants.ALERT_SEVERITY.SUCCESS,
                open: true
            })


            be.sendPost("/datagan/train/", {
                inputConnectionId: input.id,
                projectId: params.projectId
            }).then(l => {
                openSuccessDialog("Trained successfully");
            }).catch(reason => {
                if (reason.response.status!==502)
                    openErrorDialog(reason.response.data)
            });
        } else {
            setIsGenerating(true);
            be.sendPostWithFiles("/datagan/train/withTempo", {
                projectId: params.projectId
            }, input?.content).then(l => {
                openSuccessDialog("Trained successfully")
            }).catch(reason => {
                if (reason.response.status!==502)
                    openErrorDialog(reason.response.data)

            }).finally(() => refreshProject().then(l => setProject(l)));
        }
    }

    function sendGenerate() {
        if (output && output.id && number > 0) {
            setIsGenerating(true);
            be.sendPost("/datagan/generate/", {
                outputConnectionId: output?.id,
                number: number,
                projectId: params.projectId
            }).then(l => {
                openSuccessDialog("Generated successfully");
            }).catch(e => {
                if (e.response.status!=502)
                    openErrorDialog(e.response.data)
            }).finally(() => refreshProject().then(l => setProject(l)))
        }
    }

    return (
        <>
            <Grid container item columns={12} columnSpacing={2} alignItems="stretch">
                <Grid item container xs={6} alignItems={"center"} justifyContent={"center"}>
                    {(!project?.hasModel) &&
                    <InputList specific={false} setInputFromList={setInput} user={user}/>}
                    {project?.hasModel &&
                    <OutputsList specific={false} setOutputFromList={setOutput} isGenerating={isGenerating}
                                 user={user} relational={isMulti}/>}
                </Grid>
                <Grid item container xs={6} justifyContent={"center"} alignItems={"start"} alignContent={"stretch"}>
                    <Grid item container xs={12} justifyContent={"center"} alignContent={"start"}>
                        <Stack sx={{pt: 2}}>
                            <Typography>Select a item from the list on the left and click on the button to start the operation.</Typography>
                            <Typography>You'll receive an email on every step of the process so you can close this window.</Typography>
                            {input && <Typography>If you received an email of a successfully completed step while you were on
                                this page, please refresh it.</Typography>}
                        </Stack>
                    </Grid>
                    {input && !project?.hasModel && <Grid item container justifyContent={"center"} xs={12}>
                        <Grid container justifyContent={"center"} item xs={12}>
                            <Button disabled={isGenerating || project.isWorking} variant={"contained"} onClick={() => sendTrain()}>Train
                                model</Button>
                        </Grid>
                        {(isGenerating || project.isWorking) && <Grid sx={{mt: "4%"}} container justifyContent={"center"} item xs={12}>
                            <CircularProgress/>
                        </Grid>}
                    </Grid>}
                    {output && <>
                        <Grid container columns={12} alignItems={"center"} justifyContent={"center"} rowSpacing={4}>
                            <Grid item container justifyContent={"center"} xs={12}>
                                <SliderWithNumber number={number} setNumber={setNumber} max={maximumNumber}/>
                            </Grid>
                            <Grid item container justifyContent={"center"} xs={12}>
                                <Button disabled={isGenerating || project.isWorking} variant={"contained"} onClick={() => sendGenerate()}>Generate
                                    rows</Button>
                                {(isGenerating || project.isWorking) &&
                                <Grid sx={{mt: "4%"}} container justifyContent={"center"} item xs={12}>
                                    <CircularProgress/>
                                </Grid>}
                            </Grid>
                        </Grid>
                    </>}
                </Grid>
            </Grid>
        </>
    );
};

export default MainContentDashboard;
