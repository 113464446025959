import React, {useEffect, useRef, useState} from 'react';

import AceEditor from "react-ace";

import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/theme-dracula";

import {parseAndGetSyntaxErrors} from "../utils/parser/language-service"
import {ExternalConnection} from "../classes/model/ExternalConnection";


const SqlEditor = ({newOutput, setNewOutput} : {newOutput: ExternalConnection, setNewOutput: (x : ExternalConnection) => void}) => {
    const [annotations, setAnnotations] = useState([]);
    const ref = useRef(null);
    useEffect(() => {
        const { editor } = ref.current || {};
        if (annotations.length && editor) {
            editor.renderer.once('afterRender').then(() => {
                editor.getSession().setAnnotations(annotations);
            });
        }
    }, [JSON.stringify(annotations)]);

    return <div>
        <AceEditor
            mode={"sql"}
            annotations={annotations}
            theme={"dracula"}
            enableLiveAutocompletion={true}
            enableBasicAutocompletion={true}
            ref={ref}
            enableSnippets={true}
            value={newOutput.extraConnectionString}
            onChange={(e) => {
                setNewOutput({...newOutput, extraConnectionString: e, tableName: ""})
                const result = parseAndGetSyntaxErrors(e+" \n");
                setAnnotations(result);
            }}
        />
    </div>
};

export default SqlEditor;
