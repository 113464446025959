import React, {useContext, useEffect, useState} from 'react';
import ProjectList from "../../components/tables/ProjectList";
import {BackEndUtils} from "../../utils/BackEndUtils";
import NewProjectDialog from "../../components/dialogs/NewProjectDialog";
import {Project} from "../../classes/model/Project";
import {Constants} from "../../utils/Constants";
import useUser from "../../utils/hooks/useUser";
import {AlertContext} from "../../components/generic/AlertContext";
import Unauthorized from "../Unauthorized";
import TableSkeleton from "../../components/skeletons/TableSkeleton";
import {Grid} from '@mui/material';

const ProjectListPage = () => {

    const be = BackEndUtils.getInstance();
    const {setContext} = useContext(AlertContext);

    const [projects, setProjects] = useState<Project[]>([]);
    const [loading, setLoading] = useState(false);

    const [addProjectDialog, setAddProjectDialog] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [openEditProject, setOpenEditProject] = useState(false);

    const {user, canActivate} = useUser([]);

    const retrieveList = async () => {
        setLoading(true);
        const projects = await be.sendFilter("/project/filter", {
            owner: user.email
        });
        setLoading(false);
        return projects;
    }

    const refreshProjects = () => {
        if (user)
            return retrieveList().then(
                (l) => {
                    setProjects(l)
                },
                (error) => {
                    setContext({
                        text: Constants.MESSAGES.ERROR_TITLE,
                        content: Constants.MESSAGES.ERROR_CONTENT,
                        severity: Constants.ALERT_SEVERITY.ERROR,
                        open: true
                    })

                });
    }

    useEffect(() => {
        refreshProjects();
    }, [user]);


    return (
        <>
            {user && user.found && canActivate && <>
                <ProjectList
                    projects={projects}
                    loading={loading}
                    refreshProjects={refreshProjects}
                    openNewProject={() => setAddProjectDialog(true)}
                    openEditProject={() => setOpenEditProject(true)}
                    setSelectedProject={(project) => setSelectedProject(project)}
                />
                <NewProjectDialog openDialog={addProjectDialog} setOpenDialog={setAddProjectDialog}
                                  refresh={refreshProjects}
                                  user={user}/>
                <NewProjectDialog openDialog={openEditProject} setOpenDialog={setOpenEditProject} editing={true}
                                  project={selectedProject} refresh={refreshProjects} user={user}/></>}

            {user && user.found && !canActivate && <Unauthorized/>}

            {!user && <Grid sx={{width: "100%", height: "100%"}} xs={12} item container alignItems={"flex-start"}>
                <TableSkeleton/>
            </Grid>}
        </>
    );
};

export default ProjectListPage;
