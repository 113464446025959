import React, {useContext, useEffect, useState} from 'react';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {IconButton, InputAdornment, TextField} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import {useNavigate, useSearchParams} from "react-router-dom";
import AuthService from "../utils/services/auth.service"
import {LoadingButton} from "@mui/lab";
import {AlertContext} from "./generic/AlertContext";
import {Constants} from "../utils/Constants";

const ResetPasswordCard = () => {

    const {setContext} = useContext(AlertContext);
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [password, setPassword] = useState("");
    const [confirm, setConfirm] = useState("");
    const [errorConfirm, setErrorConfirm] = useState(false);
    const [errorText, setErrorText] = useState("");

    const [searchParams, setSearchParams] = useSearchParams();

    //Loading button prop
    const [requesting, setRequesting] = useState(false);


    useEffect(() => {
        checkError()
    }, [confirm, password]);



    function changePassword(event) {
        setRequesting(true)

        if (!errorConfirm)
            AuthService.updatePassword({token: searchParams.get("token"), password}).then(() => {
                    setContext({
                        text: Constants.MESSAGES.SUCCESS_TITLE,
                        content: Constants.MESSAGES.UPDATE_PSW_SUCCESS_CONTENT,
                        severity: Constants.ALERT_SEVERITY.SUCCESS,
                        open: true
                    })
                    setRequesting(false)
                    navigate("/login", {replace: true});
                },
                error => {
                    setContext({
                        text: Constants.MESSAGES.ERROR_TITLE,
                        content: Constants.MESSAGES.UPDATE_PSW_ERROR_CONTENT,
                        severity: Constants.ALERT_SEVERITY.ERROR,
                        open: true
                    })
                    setRequesting(false)
                });
    }

    function handleKeyUp(e) {
        if (e.key === 'Enter') {
            changePassword({});
        }
    }

    function checkError() {
        if (password !== confirm) {
            setErrorConfirm(true);
            setErrorText("Passwords does not match.")
        } else {
            setErrorConfirm(false);
            setErrorText("");
        }
    }

    return (
        <div>

            <Card sx={{minWidth: 275, display: "flex", flexWrap: "wrap"}} onKeyUp={handleKeyUp}>
                <CardContent sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "space-around",
                    justifyContent: "space-around"
                }}>
                    <Typography sx={{fontSize: 36, fontWeight: "bold"}} color="text.secondary" gutterBottom>
                        Reset password
                    </Typography>
                    <TextField type={showPassword ? "text" : "password"} label="Password" variant="outlined"
                               value={password}
                               style={{marginBottom: "20px"}}
                               onChange={(e) => {
                                   setPassword(e.target.value)
                               }}
                               InputProps={{
                                   endAdornment: (
                                       <InputAdornment position={"end"}>
                                           <IconButton
                                               aria-label="toggle password visibility"
                                               onClick={() => setShowPassword(!showPassword)}
                                           >
                                               {showPassword ? <Visibility/> : <VisibilityOff/>}
                                           </IconButton>
                                       </InputAdornment>
                                   )
                               }}/>
                    {/*FIXME: double eye on Microsoft Edge when password*/}

                    <TextField type={showConfirm ? "text" : "password"} label="Confirm password" variant="outlined"
                               value={confirm}
                               error={errorConfirm}
                               helperText={errorText}
                               onChange={(e) => {
                                   setConfirm(e.target.value);
                               }}
                               InputProps={{
                                   endAdornment: (
                                       <InputAdornment position={"end"}>
                                           <IconButton
                                               aria-label="toggle password visibility"
                                               onClick={() => setShowConfirm(!showConfirm)}
                                           >
                                               {showConfirm ? <Visibility/> : <VisibilityOff/>}
                                           </IconButton>
                                       </InputAdornment>
                                   ),
                               }}/>

                </CardContent>
                <CardActions sx={{width: "100%", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                    {!requesting &&
                    <Button sx={{width: "97%"}} variant={'contained'} onClick={changePassword} disabled={errorConfirm}>Change
                        passwords</Button>}
                    {requesting && <LoadingButton loading sx={{width: "97%"}} variant="outlined">
                        Submit
                    </LoadingButton>}
                </CardActions>
            </Card>
        </div>
    );
};

export default ResetPasswordCard;
