import React, {useEffect, useState} from 'react';
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {CSSObject, styled, Theme} from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import {Button, Grid} from "@mui/material";
import {Link, useLocation, useNavigate} from "react-router-dom";
import IETIcon from "./customIcons/IETIcon";
import PersonIcon from '@mui/icons-material/Person';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import OutboundIcon from '@mui/icons-material/Outbound';
import InputIcon from '@mui/icons-material/Input';
import MaterialUISwitch from "./customInputs/MaterialUISwitch";
import AuthService from "../utils/services/auth.service";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import {Constants} from "../utils/Constants";
import GenericIconAction from "./generic/GenericIconAction";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


const DataganDrawer = ({open, setOpen, dark, setDarkMode}) => {

    const hoverEffect = {
        textDecoration: "none",
        backgroundColor: "rgba(255, 255, 255, 0.1)"
    }

    const navigate = useNavigate();
    const location = useLocation();
    const [activePath, setActivePath] = useState(-1);

    function logout() {
        AuthService.logout().then(() => {
            navigate("/login", {replace: true})
        });
    }

    useEffect(() => {
        const paths = ["profile", "inputs", "outputs", "projects"]
        let index = 0;
        for (const path of paths) {
            if (location.pathname.includes(path)) {
                setActivePath(index)
                return
            }
            index++;
        }
        setActivePath(-1);
    }, [location])

    return (
        <Drawer variant="permanent" open={open}>
            <DrawerHeader>
                <Grid container columns={3} alignItems={"center"}>
                    {open &&
                    <Grid item xs={2} sx={{cursor: "pointer"}} onClick={() => {
                        navigate("/dashboard", {replace: true})
                    }}>
                        <IETIcon theme={dark}/>
                    </Grid>
                    }
                    <Grid container item xs={open ? 1 : 3} justifyContent={open ? "flex-end" : "center"}>
                        <IconButton onClick={() => setOpen(!open)}>
                            {open ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                        </IconButton>
                    </Grid>
                </Grid>
            </DrawerHeader>
            <Divider/>
            <Grid container alignItems={"stretch"} alignContent={"stretch"} sx={{height: "100%"}}>
                <Grid item xs={12}>
                    <List>
                        <ListItem button component={Link} to={"profile"} style={activePath === 0 ? hoverEffect : {}}>
                            <ListItemIcon>
                                <GenericIconAction text={"Profile Page"}
                                                   disableTooltip={open}
                                                   icon={<PersonIcon
                                                       color={activePath === 0 ? "primary" : "inherit"}/>}
                                                   tooltipDirection={Constants.TOOLTIP.PLACEMENTS.RIGHT}
                                                   color={"inherit"}/>
                            </ListItemIcon>
                            <ListItemText>Profile</ListItemText>
                        </ListItem>
                        <ListItem button component={Link} to={"inputs"} style={activePath === 1 ? hoverEffect : {}}>
                            <ListItemIcon>
                                <GenericIconAction text={"Inputs Section"}
                                                      disableTooltip={open}
                                                      icon={<InputIcon
                                                          color={activePath === 1 ? "primary" : "inherit"}/>}
                                                      tooltipDirection={Constants.TOOLTIP.PLACEMENTS.RIGHT}
                                                   color={"inherit"}/>
                            </ListItemIcon>
                            <ListItemText>Inputs</ListItemText>
                        </ListItem>
                        <ListItem button component={Link} to={"outputs"} style={activePath === 2 ? hoverEffect : {}}>
                            <ListItemIcon>
                                <GenericIconAction text={"Outputs Section"}
                                                      disableTooltip={open}
                                                      icon={<OutboundIcon
                                                          color={activePath === 2 ? "primary" : "inherit"}/>}
                                                      tooltipDirection={Constants.TOOLTIP.PLACEMENTS.RIGHT}
                                                   color={"inherit"}/>
                            </ListItemIcon>
                            <ListItemText>Outputs</ListItemText>
                        </ListItem>
                        <ListItem button component={Link} to={"projects"} style={activePath === 3 ? hoverEffect : {}}>
                            <ListItemIcon>
                                <GenericIconAction text={"Projects Section"}
                                                      disableTooltip={open}
                                                      icon={<AccountTreeIcon
                                                          color={activePath === 3 ? "primary" : "inherit"}/>}
                                                      tooltipDirection={Constants.TOOLTIP.PLACEMENTS.RIGHT}
                                                   color={"inherit"}/>
                            </ListItemIcon>
                            <ListItemText>Projects</ListItemText>
                        </ListItem>
                    </List>
                </Grid>
                <Grid container item xs={12} alignItems={"end"} alignContent={"end"} justifyContent={"center"}
                      style={{marginBottom: "1rem"}}>
                    {open &&
                    <Grid item xs={4} container justifyContent={"center"}>
                        <MaterialUISwitch checked={dark === 'dark'} onClick={() => {
                            setDarkMode(dark === 'light' ? 'dark' : 'light')
                        }}/> </Grid>}
                    <Grid container item xs={open ? 8 : 12} justifyContent={"center"}>
                        {open &&
                        <Button color="error" variant="outlined" style={{paddingRight: "1rem"}} onClick={logout}
                                startIcon={<PowerSettingsNewIcon/>}>LOGOUT</Button>}
                        {!open && <GenericIconAction text={"Logout"} icon={<PowerSettingsNewIcon color={"error"}/>}
                                                        tooltipDirection={Constants.TOOLTIP.PLACEMENTS.RIGHT}
                                                        disableTooltip={open} color={"inherit"}/>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Drawer>
    );
};

export default DataganDrawer;
