import * as React from 'react';
import LoginCard from "../components/LoginCard";
import Box from "@mui/material/Box";
import HeaderBar from "../components/HeaderBar";
import useUser from "../utils/hooks/useUser";
import {Navigate} from "react-router-dom";
import {styled} from "@mui/material/styles";


function LoginPage({mode, setMode}) {

    const {user, canActivate} = useUser([]);

    const CustomBox = styled('div')(({theme}) => ({
        root: {
            [theme.breakpoints.up('md')]: {
                width: '30%'
            },
            [theme.breakpoints.down('md')]: {
                width: '50%'
            },
            [theme.breakpoints.down('sm')]: {
                width: '60%'
            }
        }
    }));

    return (
        <>
            {user && !user.found && <>
                <HeaderBar mode={mode} setMode={setMode}/>
                <Box sx={{
                    display: "flex",
                    mt: "8%",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignContent: "center",
                    alignSelf: "center",
                }}>
                    <CustomBox>
                        <LoginCard/>
                    </CustomBox>
                </Box>
            </>}
            {user && user.found && <Navigate to={"/dashboard"} replace={true}/>}
        </>
    );
}

export default LoginPage;
