import api from "../utils/services/api"

// const headers = new Headers({
//     "Content-Type": "application/json"
// });
//
// const publicHeaders = new Headers({
//     "Sign-Token": "QqfPlV9VdOzb6MZtji",
//     "Content-Type": "application/json"
//
// })
// const headersData = new Headers({
//     "Content-Type": "multipart/form-data",
//     "Accept-Encoding": "multipart/form-data"
// })

export class BackEndUtils {

    gateway = process.env.NODE_ENV == "production" ? "/auth-framework-0.0.1/gateway" : "/gateway";

    accessToken: string = "";
    refreshToken: string = "";

    private constructor() {
    }

    static instance: BackEndUtils = null;

    static getInstance() {
        if (this.instance === null) {
            this.instance = new BackEndUtils();
        }
        return this.instance;
    }

    sendPost(path: string, body: any) {
        path = this.gateway + path;
        return api.post(path, body).then(a => a.data)
        // return fetch(path, {
        //     headers: headers,
        //     method: 'POST',
        //     body: JSON.stringify(body)
        // }).then(this.parseJson)
    }

    private parseJson = async response => {
        const text = await response.text()
        try {
            return JSON.parse(text)
        } catch (err) {
            return Promise.resolve(text)
        }
    }


    buildFormDataForFiles(body, file): FormData {
        const formData = new FormData();
        for (const bodyKey in body) {
            formData.append(bodyKey, body[bodyKey])
        }
        formData.append(`csv`, file);
        return formData;
    }

    sendPostWithFiles(path, body, file) {
        path = this.gateway + path;
        const formData = this.buildFormDataForFiles(body, file);
        return api.post(path, formData).then(a => a.data)
        // return fetch(path, {
        //     method: 'POST',
        //     // headers: headersData,
        //     body: formData
        // }).then(this.parseJson)
    }

    sendGet(path: string) {
        path = this.gateway + path;
        return api.get(path).then(a => a.data);
        // return fetch(path).then(l => l.json());
    }

    sendGetById(path: string, id: number) {
        path = this.gateway + path;
        return api.get(path + "/" + id).then(a => a.data);
    }

    sendFilter(path: string, filter) {
        path = this.gateway + path;
        return api.post(path, filter).then(a => a.data);
    }

    sendPut(path: string, body: any) {
        path = this.gateway + path;
        return api.put(path, body).then(a => a.data);
    }

    sendDelete(path: string) {
    }

    sendPutWithFiles(path: string, body, file) {
        path = this.gateway + path;
        const formData = new FormData();
        for (const bodyKey in body) {
            formData.append(bodyKey, body[bodyKey])
        }
        formData.append(`csv`, file);

        return api.put(path, formData).then(a => a.data);
    }

    sendGetForBlob(path: string) {
        path = this.gateway + path;
        return api.get(path, {
            responseType: "blob"
        }).then(a => a.data);
        // return fetch(path).then(l => l.blob());
    }
}
