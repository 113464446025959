import React, {useContext, useEffect} from "react";
import {SvgIcon} from "@mui/material";
import {AlertContext} from "../generic/AlertContext";


function OpenIcon() {
    return (
        <SvgIcon height="36px" viewBox="0 0 24 24" enableBackground="new 0 0 24 24" fontSize={"large"}
                 width="36px" color={"primary"}>
            <g>
                <rect fill="none" height="24" width="24"/>
            </g>
            <g>
                <path
                    d="M22,12c0-5.52-4.48-10-10-10S2,6.48,2,12c0,5.52,4.48,10,10,10S22,17.52,22,12z M12,13l-4,0v-2l4,0V8l4,4l-4,4V13z"/>
            </g>
        </SvgIcon>
    );
}

export default OpenIcon;
