import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import SwipeableTextMobileStepper from "./SwipeableTextMobileStepper";

export default function VerticalLinearStepper({mode}) {
    const [activeStep, setActiveStep] = React.useState(0);
    const imagesInputs = [
        {
            label: [`To start generating your synthetic data, you have to provide a dataset that will be used to train the GAN model which will generate your data.`, //a capo
                `You can choose many types of input to provide your dataset and to configure your inputs you click on "Inputs" from the main menu on the left.`],
            imgPath:
                `carousel_images/${mode}/inputs/step1.png`,
        },
        {
            label: [`This is the section where you collect the inputs of all the datasets you want to use to train your models.`,//a capo
                `You also find here some default datasets we provide to you to start experimenting with DataGAN even if you still haven\`t your own datasets. `, //a capo
                `Go on with the wizard if you have your dataset.`],
            imgPath:
                `carousel_images/${mode}/inputs/step2.png`,
        },
        {
            label: `With the first button you can manually refresh the input list. With the second one, you can create a new input.`,
            imgPath:
                `carousel_images/${mode}/inputs/step3.png`,
        },
        {
            label: [`This is the popup through which you can create a new input. The first parameter to choose is the "Model type". `,
                `There are three of them: `,
                [`Single table: it\`s just a tabular set of data inserted either as csv or obtained via a database connection to a single table`,
                `Relational: it\`s a zip file with a json file that contains some metadata about the schema, relations and output of a multi-table dataset and optionally the input files. You can get more info downloading the zip example we provide.`,
                `Timeseries: it\`s like single table but specifically for data where you have to indicate the time column and optionally the columns that indicates a unique entity in your dataset`]
            ],
            imgPath:
                `carousel_images/${mode}/inputs/step4.png`,
        },
        {
            label: `For single table and timeseries models you can choose between a permanent file or a database connection.`,
            imgPath:
                `carousel_images/${mode}/inputs/step5.png`,
        },
        {
            label: `If you choose "Permanent File", you have to insert an input name and a description. It is also required to upload a file from which DataGAN will take all data.`,
            imgPath:
                `carousel_images/${mode}/inputs/step6.png`,
        },
        {
            label: [`If you choose "Postgres", there are two different ways to specify a Postgres input. You can switch from one to another with the appropriate button.`,
                `This is the "Table" visualization where you can provide all fields related to a JDBC connection.`],
            imgPath:
                `carousel_images/${mode}/inputs/step7.png`,
        },
        {
            label: `This is the "Query" visualization where you can provide also a query to select a specific set of data using joins and other SQL statements.`,
            imgPath:
                `carousel_images/${mode}/inputs/step8.png`,
        },
        {
            label: [`This is the visualization for the relational model type. As before you have to insert a name and description for the input.`,`You can download an example file with also a README.md inside that will guide you compiling the json or you can upload it on your own.`],
            imgPath:
                `carousel_images/${mode}/inputs/step9.png`,
        },
        {
            label: `When you have created an input you can edit it through the appropriate button, on the last column of the table.`,
            imgPath:
                `carousel_images/${mode}/inputs/step10.png`,
        }
    ];
    const imagesOutputs = [
        {
            label: `On the left you can find the Menu. Clicking here, you can navigate in Output Section.`,
            imgPath:
                `carousel_images/${mode}/outputs/step1.png`,
        },
        {
            label: `Inside you can find the list of outputs that you have already created.`,
            imgPath:
                `carousel_images/${mode}/outputs/step2.png`,
        },
        {
            label: `With the first button you can manually refresh the output list. With the second one, you can create a new one.`,
            imgPath:
                `carousel_images/${mode}/outputs/step3.png`,
        },
        {
            label: `This is the popup through which you can create a new output. There are many different outputs. Choose the most appropriate according to your needs.`,
            imgPath:
                `carousel_images/${mode}/outputs/step4.png`,
        },
        {
            label: `If you have chosen Postgres, you need to specify all the required fields for a ODBC connection as well as you have done in the input section.`,
            imgPath:
                `carousel_images/${mode}/outputs/step5.png`,
        },
        {
            label: `If you have chosen Kafka, you need to set up a connection related to a specific topic.`,
            imgPath:
                `carousel_images/${mode}/outputs/step6.png`,
        },
        {
            label: `If you have chosen MQTT, you need to set up a connection related to a specific topic.`,
            imgPath:
                `carousel_images/${mode}/outputs/step7.png`,
        },
        {
            label: [`For every type seen before you can flag it as "Relational". A relational output can be used only with relational inputs.`,
                `In this case you won\`t need to specify a topic or table, these will be taken from the "table" name in your input json.`],
            imgPath:
                `carousel_images/${mode}/outputs/step8.png`,
        },
        {
            label: [`If you have chosen Permanent File, you need to specify all the required fields for an output file as well as you have done in the input section.`, `In case of relational output the file name will be the name of the zip you\`ll download`],
            imgPath:
                `carousel_images/${mode}/outputs/step9.png`,
        },
        {
            label: [`When you have created an output you can edit it through the appropriate button, once you have selected it.`,
            "You can also download it's content if it's a \"Permanent file\" type."],
            imgPath:
                `carousel_images/${mode}/outputs/step10.png`,
        }
    ];
    const imagesProjects = [
        {
            label: `On the left you can find the Menu. Clicking here, you can navigate in Project Section.`,
            imgPath:
                `carousel_images/${mode}/projects/step1.png`,
        },
        {
            label: `Inside you can find the project list of projects that you have already created.`,
            imgPath:
                `carousel_images/${mode}/projects/step2.png`,
        },
        {
            label: `With the first button you can create a new project. With the second one, you can manually refresh the project list.`,
            imgPath:
                `carousel_images/${mode}/projects/step3.png`,
        },
        {
            label: `Through this popup you can create a new project by specifying a name and description.`,
            imgPath:
                `carousel_images/${mode}/projects/step4.png`,
        },
        {
            label: `Once you have created a new project, you can open it with the appropriate button.`,
            imgPath:
                `carousel_images/${mode}/projects/step5.png`,
        },
        {
            label: `Here you can select one of the inputs that you have already created or add a temporary input. To start training, simply click the TRAIN button.`,
            imgPath:
                `carousel_images/${mode}/projects/step6.png`,
        },
        {
            label: `Once the training process has completed successfully there is the possibility to generate a certain amount of rows and retrieve it from one of your outputs.`,
            imgPath:
                `carousel_images/${mode}/projects/step7.png`,
        }
    ];

    const steps = [
        {
            label: `Welcome to DataGAN`,
            description: `Hi there! First thing first: thank you for joining us. In this and the following wizards, we will quickly help you getting started using DataGAN. ` + //andare a capo qui
                `To start your journey click the following "Inputs section".`,
        },
        {
            label: `Inputs Section`,
            description: <SwipeableTextMobileStepper images={imagesInputs}/>,
        },
        {
            label: `Outputs Section`,
            description:
                <SwipeableTextMobileStepper images={imagesOutputs}/>
        },
        {
            label: `Projects Section`,
            description: <SwipeableTextMobileStepper images={imagesProjects}/>
        },
    ];

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <Box>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel onClick={() => setActiveStep(index)} sx={{cursor: "pointer"}}>
                            <Typography variant="h4" onClick={() => setActiveStep(index)}
                                        sx={{cursor: "pointer"}}>{step.label}</Typography>
                        </StepLabel>
                        <StepContent>
                            {step.description}
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <Paper square elevation={0} sx={{p: 3}}>
                    <Typography>All steps completed - you&apos;re ready to start</Typography>
                    <Button onClick={handleReset} sx={{mt: 1, mr: 1}}>
                        Reset
                    </Button>
                </Paper>
            )}
        </Box>
    );
}
