import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import {AlertTitle, Snackbar} from "@mui/material";

export default function GenericAlert({title, content, variant, severity, open, handleClose}) {
    return (
        <Box sx={{width: '30%', position: 'absolute', bottom: '1%', right: '1%'}}>
            <Collapse in={open}>
                <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                    <Alert
                        action={
                            <IconButton aria-label="close" color="inherit" size="small" onClick={handleClose}>
                                <CloseIcon fontSize="inherit"/>
                            </IconButton>
                        }
                        sx={{mb: 2}}
                        severity={severity}
                        variant={variant}
                    >
                        <AlertTitle>{title}</AlertTitle>
                        {content}
                    </Alert>
                </Snackbar>
            </Collapse>
        </Box>
    );
}
