import React from 'react';
import {Grid} from "@mui/material";
import TextField from "@mui/material/TextField";
import {Constants} from "../../../utils/Constants";

const KafkaForm = ({newOutput, setNewOutput}) => {
    return (
        <>
            <Grid item xs={6}>
                <TextField
                    margin="dense"
                    id="IP"
                    label="Host"
                    value={newOutput.host}
                    onChange={(i) => setNewOutput({...newOutput, host: i.target.value})}
                    type="text"
                    fullWidth
                    variant="standard"
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    margin="dense"
                    id="port"
                    label="Port"
                    value={newOutput.port}
                    type="text"
                    onChange={(i) => setNewOutput({...newOutput, port: i.target.value})}
                    fullWidth
                    variant="standard"
                />
            </Grid>
            {newOutput.modelType !== Constants.MODEL_TYPE.MULTI_TABLE &&
            <Grid item xs={8}>
                <TextField
                    margin="dense"
                    id="topicName"
                    label="Topic name"
                    value={newOutput.topicName}
                    type="text"
                    onChange={(i) => setNewOutput({...newOutput, topicName: i.target.value})}
                    fullWidth
                    variant="standard"
                />
            </Grid>}
        </>
    );
};

const MQTTForm = ({newOutput, setNewOutput}) => {
    return (
        <>
            <KafkaForm newOutput={newOutput} setNewOutput={setNewOutput}/>
            <Grid item xs={6}>
                <TextField
                    margin="dense"
                    id="Username"
                    label="Username"
                    value={newOutput.username}
                    onChange={(i) => setNewOutput({...newOutput, username: i.target.value})}
                    type="text"
                    fullWidth
                    variant="standard"
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    margin="dense"
                    id="password"
                    label="Password"
                    value={newOutput.password}
                    onChange={(i) => setNewOutput({...newOutput, password: i.target.value})}
                    type="text"
                    fullWidth
                    variant="standard"
                />
            </Grid>
        </>
    )
}

export {MQTTForm};
export default KafkaForm;
