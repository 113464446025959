import React, {useEffect, useState} from 'react';
import {CustomLoadingOverlay} from "./ProjectList";
import {DataGrid, GridRenderCellParams, GridToolbarContainer} from "@mui/x-data-grid";
import {BackEndUtils} from "../../utils/BackEndUtils";
import DialogExternalConnection from "../dialogs/DialogExternalConnection";
import Button from "@mui/material/Button";
import {ExternalConnection} from "../../classes/model/ExternalConnection";
import {CircularProgress, Grid, Typography} from "@mui/material";
import {Constants} from "../../utils/Constants";
import {GridRTL} from "../generic/GenericGridRTL";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import GenericIconAction from "../generic/GenericIconAction";


const CustomToolbar = ({refreshOutputs, openNewOutput, specific}) => {
    return (
        <GridToolbarContainer>
            <Grid container alignItems={"center"}>
                <Grid item xs={4}>
                    <Typography variant="h4" component="h2" style={{padding: ".7rem"}}>
                        Outputs List
                    </Typography>
                </Grid>
                <GridRTL item xs={8} dir={"rtl"}>
                    {specific &&
                        <Button onClick={() => openNewOutput()} variant={"contained"}>Add output</Button>}
                    <Button onClick={() => refreshOutputs()} variant={"outlined"}>Refresh outputs</Button>
                </GridRTL>
            </Grid>
        </GridToolbarContainer>
    )
}

const OutputsList = ({
                         specific = false, setOutputFromList = (c) => {
    }, isGenerating = false, user, relational = null
                     }) => {

    const columns = [
        {field: 'connectionName', headerName: 'Output name', width: 150},
        {field: 'type', headerName: "Type", width: 150},
        {field: 'description', headerName: "Description", flex: 1},
        {
            field: 'done', headerName: "", width: 200, renderCell: (params: GridRenderCellParams) => {
                return (
                    <Grid container alignItems={"center"} justifyContent={"center"}>
                        {selectedOutput.id === params.row.id && isGenerating && params.row.type === Constants.EXTERNAL_TYPE.FILE && !params.row.pathToExternal &&
                            <CircularProgress/>}
                        {params.row.pathToExternal &&
                            <GenericIconAction text={"Download Output"}
                                               icon={<DownloadForOfflineIcon fontSize={"large"}/>}
                                               tooltipDirection={Constants.TOOLTIP.PLACEMENTS.TOP}
                                               disableTooltip={false}
                                               color={"primary"}
                                               action={() => {
                                                   let path = retrievingPath(params.row)
                                                   be.sendGetForBlob("/datagan/retrieve/" + (path)).then(blob => {
                                                       const href = window.URL.createObjectURL(blob);
                                                       const link = document.createElement('a');
                                                       link.href = href;
                                                       link.setAttribute('download', filename(params.row)); //or any other extension
                                                       document.body.appendChild(link);
                                                       link.click();
                                                       document.body.removeChild(link);
                                                   })
                                               }}/>}
                        <GenericIconAction text={"Edit Output"}
                                           icon={<BuildCircleIcon fontSize="large" color={"primary"}/>}
                                           tooltipDirection={Constants.TOOLTIP.PLACEMENTS.TOP}
                                           disableTooltip={false} color={"primary"} action={openEditOutputFunc}/>
                    </Grid>
                )
            }
        }
    ];

    const [selectionModel, setSelectionModel] = useState<any[]>([]);
    const [rows, setRows] = useState<ExternalConnection[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [openNewOutput, setOpenNewOutput] = useState<boolean>(false);
    const [openEditOutput, setOpenEditOutput] = useState<boolean>(false);
    const [selectedOutput, setSelectedOutput] = useState<ExternalConnection>(new ExternalConnection());
    const be = BackEndUtils.getInstance();

    useEffect(() => {
        refreshOutputs()
    }, [relational]);

    const retrievingPath = (row) =>{
        let path = row.pathToExternal.substring(0, row.pathToExternal.length - 4)
        if(row.connectionName === 'excel')
            return path + ".xlsx"
        else{
            return row.pathToExternal
        }
    }

    const filename = (row) => {
        switch (row.modelType) {
            case Constants.MODEL_TYPE.MULTI_TABLE: {
                return row.fileName + ".zip"
            }
            case Constants.MODEL_TYPE.SINGLE_TABLE: {
                // return row.fileName + ".csv"
                return row.fileName + (row.connectionName === 'excel' ? ".xlsx" : '.csv')
            }
        }

    }

    const refreshOutputs = () => {
        if (user) {
            setLoading(true);
            be.sendFilter("/external-connection/filter", {
                "input": false,
                "relational": relational,
                owner: user.email
            }).then(l => {
                const m = l.map((e: ExternalConnection) => {
                    if (e.extraConnectionString)
                        e.extraConnectionString = window.atob(e.extraConnectionString);
                    return Object.assign(new ExternalConnection(), e);
                })
                setRows(m);
                setLoading(false);
            });
        }
    }

    const openEditOutputFunc = () => {
        setOpenEditOutput(true);
    }

    useEffect(() => {
        refreshOutputs();
    }, [isGenerating, user]);

    return (
        <>
            <DataGrid columns={columns} rows={rows}
                      components={{Toolbar: CustomToolbar, LoadingOverlay: CustomLoadingOverlay}}
                      onSelectionModelChange={(newSelectionModel) => {
                          setSelectionModel(newSelectionModel);
                          for (let x of rows)
                              if (newSelectionModel[0] === x?.id) {
                                  setSelectedOutput(x);
                                  setOutputFromList(x);
                              }
                      }}
                      selectionModel={selectionModel}
                      loading={loading}
                      componentsProps={{
                          toolbar: {
                              refreshOutputs: refreshOutputs,
                              openNewOutput: () => setOpenNewOutput(true),
                              openEditOutput: openEditOutputFunc,
                              isSomethingSelected: selectionModel[0],
                              specific
                          }
                      }}
            />
            <DialogExternalConnection openDialog={openNewOutput}
                                      setOpenDialog={setOpenNewOutput}
                                      isEditing={false}
                                      externalConnection={new ExternalConnection()}
                                      refreshInputs={refreshOutputs}
                                      user={user}

            />
            <DialogExternalConnection openDialog={openEditOutput}
                                      setOpenDialog={setOpenEditOutput}
                                      isEditing={true}
                                      externalConnection={selectedOutput}
                                      refreshInputs={refreshOutputs}
                                      user={user}

            />
        </>
    );
};
export default OutputsList;
