import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import DashboardHome from "./pages/dashboard/DashboardHome.tsx";
import ForgotPasswordPage from "./pages/ForgotPasswordPage.tsx";
import WizardPage from "./pages/WizardPage.tsx";

import LoginPage from "./pages/LoginPage";
import * as React from "react";
import MainContentDashboard from "./pages/dashboard/MainContentDashboard";
import ProjectListPage from "./pages/dashboard/ProjectListPage";
import ProjectsHomepage from "./pages/dashboard/ProjectsHomepage";
import OutputsPage from "./pages/dashboard/OutputsPage";
import InputsPage from "./pages/dashboard/InputsPage";
import ProfilePage from "./pages/ProfilePage";
import Unauthorized from "./pages/Unauthorized";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import {ThemeProvider} from "@emotion/react";
import {createTheme, responsiveFontSizes, useMediaQuery} from "@mui/material";
import {Grid} from "@mui/material";
import {Constants} from "./utils/Constants";
import GenericAlert from "./components/generic/GenericAlert";
import {AlertContext} from "./components/generic/AlertContext";
import {useState} from "react";
import LandingPageV2 from "./pages/LandingPageV2";
import VerifyEmail from "./pages/VerifyEmail";

const ColorModeContext = React.createContext({
    toggleColorMode: () => {
    }
});

function App() {
    const [mode, setMode] = React.useState('dark');
    const colorMode = React.useMemo(() => ({
        toggleColorMode: () => {
            setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
        },
    }), [],);
    let theme = React.useMemo(() => createTheme({
        typography: {
            fontFamily: '"Raleway", san-serif',
            fontSize: 14,
            fontWeightLight: 400,
            fontWeightRegular: 500,
            fontWeightMedium: 700,
            button: {
                fontWeight: 700
            }
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        // Some CSS
                       borderRadius: "100px",
                    },
                },
            },
        },
        palette: {
            mode,
            ...(mode === 'light') ? {
                primary: {
                    light: "#ea6464",
                    main: Constants.COLORS.PURPLE,
                    dark: Constants.COLORS.PURPLE,
                    contrastText: Constants.COLORS.WHITE,
                },
                text: {
                    primary: Constants.COLORS.BLACK
                }
            } : {
                text: {
                    primary: Constants.COLORS.WHITE
                },
                background: {
                    default: Constants.COLORS.BLACK,
                    paper: Constants.COLORS.BLACK
                },
                primary: {
                    light: "#ea6464",
                    main: Constants.COLORS.BLUE,
                    dark: Constants.COLORS.BLUE,
                    contrastText: Constants.COLORS.BLACK,
                },
            }
        },
        breakpoints: {
            values: {
                xxs: 0, //default 0
                xs: 376,
                sm: 600, //default 600
                md: 900, //default 900
                lg: 1200, //default 1200
                lgr: 1400,
                xl: 1700,
            },
        },
    }), [mode]);

    theme = responsiveFontSizes(theme);

    const [context, setContext] = useState({
        title: "",
        content: "",
        severity: "",
        open: false
    });

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <AlertContext.Provider value={{context, setContext}}>
                    <Grid
                        container
                        columns={12}
                        justifyContent="center"
                        alignItems="stretch"
                        height={"100vh"}
                        sx={{backgroundColor: theme.palette.background.default}}
                    >
                        <Grid item xs={12}>
                            <BrowserRouter>
                                <Routes>
                                    <Route path={"/"} element={<LandingPageV2 mode ={mode} setMode={setMode}/>}/>
                                    <Route path={"/dashboard/"}
                                           element={<DashboardHome mode={mode} setMode={setMode}/>}>
                                        <Route path={""} element={<WizardPage mode={mode}/>}/>
                                        <Route path={"profile"} element={<ProfilePage/>}/>
                                        <Route path={"projects"} element={<ProjectsHomepage/>}>
                                            <Route path={""} element={<ProjectListPage/>}/>
                                            <Route path={":projectId"} element={<MainContentDashboard/>}/>
                                        </Route>
                                        <Route path={"outputs"} element={<OutputsPage/>}/>
                                        <Route path={"inputs"} element={<InputsPage/>}/>
                                    </Route>
                                    <Route path={"/login"} element={<LoginPage mode={mode} setMode={setMode}/>}/>
                                    <Route path={"/forgot-password"}
                                           element={<ForgotPasswordPage mode={mode} setMode={setMode}/>}/>
                                    <Route path={"/reset-password"}
                                           element={<ResetPasswordPage mode={mode} setMode={setMode}/>}/>
                                    <Route path={"/verify-email"}
                                           element={<VerifyEmail mode={mode} setMode={setMode}/>}/>
                                    <Route path="*"
                                        element={<Unauthorized/>}
                                    />
                                </Routes>
                            </BrowserRouter>
                        </Grid>
                    </Grid>
                    <GenericAlert
                        title={context.title}
                        content={context.content}
                        open={context.open} handleClose={() => {
                        setContext({...context, open: false})
                    }}
                        variant={Constants.ALERT_VARIANT.FILLED}
                        severity={context.severity}/>
                </AlertContext.Provider>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}

export default App;
