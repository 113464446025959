import React from 'react';
import VerticalLinearStepper from "../components/wizard/VerticalLinearStepper";
import {Grid} from "@mui/material";


const WizardPage = ({mode}) => {
    return (
        <Grid container columns={12} justifyContent={"center"} alignContent={"center"} alignItems={"center"}>
            <Grid container item xs={12} md={8} lg={6} alignSelf={"center"} justifyContent={"center"}>
                <VerticalLinearStepper mode={mode}/>
            </Grid>
        </Grid>
    );
};

export default WizardPage;
