import React from 'react';
import OutputsList from "../../components/tables/OutputsList";
import useUser from "../../utils/hooks/useUser";
import Unauthorized from "../Unauthorized";
import {Grid} from "@mui/material";
import TableSkeleton from "../../components/skeletons/TableSkeleton";

const OutputsPage = () => {


    const {user, canActivate} = useUser([]);

    return (
        <>
            {user && user.found && canActivate && <OutputsList specific={true} setOutputFromList={() => {}} user={user}/>}
            {user && user.found && !canActivate && <Unauthorized/>}
            {!user && <Grid sx={{width: "100%", height: "100%"}} xs={12} item container alignItems={"flex-start"}>
		        <TableSkeleton/>
	        </Grid>}
        </>
    );
};

export default OutputsPage;
