export class Constants {
    public static EXTERNAL_TYPE = {
        FILE: "file",
        TEMPO: "temporary",
        POSTGRES: "postgres",
        KAFKA: "kafka",
        MQTT: "mqtt",
    }

    public static MODEL_TYPE = {
        SINGLE_TABLE: "singleTable",
        MULTI_TABLE: "relational",
        TIME_SERIES: "timeSeries",
    }

    public static ROLES_LEVEL = {
        ZERO: ["", "ROLE_USER, ROLE_ADMIN"],
        ONE: ["ROLE_USER"],
        TWO: ["ROLE_ADMIN"]
    }

    public static ALERT_SEVERITY = {
        ERROR: "error",
        WARNING: "warning",
        INFO: "info",
        SUCCESS: "success"
    }

    public static ALERT_VARIANT = {
        FILLED: "filled",
        OUTLINED: "outlined"
    }

    public static MESSAGES = {
        SUCCESS_TITLE: 'Success',
        ERROR_TITLE: 'Error',
        SUCCESS_CONTENT: 'Operation executed successfully',
        ERROR_CONTENT: 'Cannot execute operation. Try again later.',
        REGISTRATION_SUCCESS_CONTENT: 'Your account has been registered successfully. Check your email to confirm it.',
        REGISTRATION_ERROR_CONTENT: 'The registration process cannot be completed. Try again later.',
        LOGIN_SUCCESS_CONTENT: '',
        LOGIN_ERROR_CONTENT: 'Login failed. Try again later.',
        FORGOT_PSW_SUCCESS_CONTENT: 'Check your email to reset your password.',
        FORGOT_PSW_ERROR_CONTENT: 'Forgot Password request failed. Try again later.',
        UPDATE_PSW_SUCCESS_CONTENT: 'Password updated successfully.',
        UPDATE_PSW_ERROR_CONTENT: 'Cannot update password. Try again later.'
    }

    public static COLORS = {
        BLUE: "#00D3FF",
        PURPLE: "#5650A2",
        BLACK: "#030609",
        WHITE: "#FFF"
    }

    public static TOOLTIP = {
        PLACEMENTS: {
            TOP_START: "top-start",
            TOP: "top",
            TOP_END: "top-end",
            RIGHT_START: "right-start",
            RIGHT: "right",
            RIGHT_END: "right-end",
            BOTTOM_END: "bottom-end",
            BOTTOM: "bottom",
            BOTTOM_START: "bottom-start",
            LEFT_END: "left-end",
            LEFT: "left",
            LEFT_START: "left-start"
        }
    }

    public static CAPTCHA_SITE_KEY = "6LcEHoAeAAAAAB7kjJ2qN3ec--WLi088Qmegc8KX";

    public static emailRegex = new RegExp("^(([^<>()[\\]\\\\.,;:\\s@\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$");
    public static passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
}
