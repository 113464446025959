import React, {useState} from 'react';
import {FormControlLabel, FormGroup, Grid, Switch} from "@mui/material";
import TextField from "@mui/material/TextField";

import PropTypes from 'prop-types';
import SqlEditor from "../../SqlEditor";
import {ExternalConnection} from "../../../classes/model/ExternalConnection";
import {Constants} from "../../../utils/Constants";


const DBForm = ({newOutput, setNewOutput, forInput} : {newOutput : ExternalConnection, setNewOutput : (x: ExternalConnection) => void, forInput: boolean}) => {
    const [withQuery, setWithQuery] = useState(newOutput.extraConnectionString != null && newOutput.extraConnectionString != "");

    return (
        <>
            <Grid item xs={6}>
                <TextField
                    margin="dense"
                    id="IP"
                    label="Host"
                    value={newOutput.host}
                    onChange={(i) => setNewOutput({...newOutput, host: i.target.value})}
                    type="text"
                    fullWidth
                    variant="standard"
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    margin="dense"
                    id="port"
                    label="Port"
                    value={newOutput.port}
                    type="text"
                    onChange={(i) => setNewOutput({...newOutput, port: Number(i.target.value)})}
                    fullWidth
                    variant="standard"
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    margin="dense"
                    id="database"
                    label="Database"
                    value={newOutput.database}
                    type="text"
                    onChange={(i) => setNewOutput({...newOutput, database: i.target.value})}
                    fullWidth
                    variant="standard"
                />
            </Grid>
            <Grid item container alignItems={"center"} xs={6}>
                <Grid item xs={12} alignSelf={"end"}>
                    <FormGroup>
                        <FormControlLabel control={<Switch disabled={!forInput} onChange={() => setWithQuery(!withQuery)} checked={withQuery}/>} label={"Query"} sx={{fontSize: "10px", marginRight: "0px"}}/>
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    margin="dense"
                    id="user"
                    label="User"
                    type="text"
                    value={newOutput.username}
                    onChange={(i) => setNewOutput({...newOutput, username: i.target.value})}
                    fullWidth
                    variant="standard"
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    margin="dense"
                    id="password"
                    label="Password"
                    type="password"
                    value={newOutput.password}
                    onChange={(i) => setNewOutput({...newOutput, password: i.target.value})}
                    fullWidth
                    variant="standard"
                />
            </Grid>
            {(!withQuery && newOutput.modelType !== Constants.MODEL_TYPE.MULTI_TABLE) &&
	        <Grid item xs={12}>
		        <TextField
			        margin="dense"
			        id="table"
			        label="Table name"
			        value={newOutput.tableName}
			        type="text"
			        onChange={(i) => setNewOutput({...newOutput, tableName: i.target.value, extraConnectionString: ""})}
			        fullWidth
			        variant="standard"
		        />
	        </Grid>
            }
            {withQuery && <Grid item xs={12} style={{marginTop: ".5rem"}}>
                <SqlEditor newOutput={newOutput} setNewOutput={setNewOutput}/>
            </Grid>}
        </>
    );
};

DBForm.propTypes = {
    newOutput: PropTypes.object
};

export default DBForm;
